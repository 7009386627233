var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test-version-controller",class:{'test-select-filter': _vm.$store.state.selectTestsModeActive && _vm.scrolledTopic == _vm.topicIndex}},[_c('div',{staticClass:"select-container"},[(_vm.parentTest && _vm.parentTest.options && Array.isArray(_vm.parentTest.versions))?_c('TestDropdown',{staticClass:"version-dropdown",class:{'active': _vm.parentTest.options.active_version_id == 0 ? _vm.test.id == _vm.parentTest.id && _vm.topic.questions == _vm.parentTest.id : _vm.parentTest.options.active_version_id == _vm.test.id && _vm.topic.questions == _vm.parentTest.id},attrs:{"title":_vm.parentTest.name,"expandedTitle":_vm.toLocal('test.select_version'),"test":_vm.test,"dropDownList":[_vm.parentTest ].concat( _vm.parentTest.versions),"selectedItemID":_vm.selectedVersion,"selectedFilter":0,"parentTest":_vm.parentTest,"topic":_vm.topic,"type":'version'},on:{"selectedItem":function($event){_vm.$emit('selectedVersion', $event); _vm.selectedVersion = $event},"flattenedList":function($event){_vm.dropdownFlattenedList = $event},"deleteVersion":function($event){return _vm.deleteVersion($event)}}}):_vm._e()],1),_c('div',{staticClass:"btn",on:{"click":_vm.createNewVersion}},[_c('div',[_vm._v(_vm._s(_vm.toLocal('tests.create_copy')))]),_c('div',{staticClass:"icon-container",class:{loading: _vm.addingLoading}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15.627","height":"16.628","viewBox":"0 0 15.627 16.628"}},[_c('path',{attrs:{"id":"Path_5899","data-name":"Path 5899","d":"M34.615,14.061H31.022v6.4H25.005v3.823h6.017v6.4h3.593v-6.4h6.017V20.463H34.615Z","transform":"translate(-25.005 -14.061)","fill":"#28345c"}})])])]),(_vm.parentTest && _vm.parentTest.options)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.test.type == 1 && _vm.test.options.tests && _vm.test.options.tests.length == 0 ? _vm.toLocal('tests.add_test_questions') : '',
          classes: 'tooltip-container',
          }
      ),expression:"{\n          content: test.type == 1 && test.options.tests && test.options.tests.length == 0 ? toLocal('tests.add_test_questions') : '',\n          classes: 'tooltip-container',\n          }\n      "}],staticClass:"btn activate",class:{
      'active': _vm.parentTest.options.active_version_id == 0 ? _vm.test.id == _vm.parentTest.id && _vm.topic.questions == _vm.parentTest.id : _vm.parentTest.options.active_version_id == _vm.test.id && _vm.topic.questions == _vm.parentTest.id,
      'disabled': _vm.test.type == 1 && _vm.test.options.tests && _vm.test.options.tests.length == 0
      },on:{"click":_vm.activateVersion}},[_vm._v(" "+_vm._s(_vm.toLocal('tests.publish_test'))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }