<template>
  <div class="button-container">
        <div class="btn secondary" :class="{
            deactivated: formType == 'momentary' && !activeFormed, 
            deactivated: !reportStatusActive, 
            active: report.isActive == 1}" @click="reportAction(formType)
        ">
            {{formType == 'momentary' ? toLocal('form_report.download_report') : report.isActive == 1 ? toLocal('report.isActive') : toLocal('form_report.activate_downloading')}} 
            <svg v-if="report.isActive != 1" width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M11.9844 8L0.999999 14.2768L1 1.72318L11.9844 8Z" stroke="#4D4D4D" stroke-width="2"/>
            </svg>
            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7.52154L6.23189 14.2992L9.31403 9.86985L15 1.69922" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
      <div class="row">
          <div 
            class="btn" 
            :class="{deactivated: !activeForm}" 
            @click="formReport"
          >
            {{$store.state.reportForming.reportIsForming ? toLocal('report.forming') : toLocal('report.form_report')}}
            <span>{{$store.state.reportForming.reportIsForming ? $store.state.reportForming.progress+' %' : '' }}</span>
          </div>
          <div class="btn icon" :class="{deactivated: !activeFormed}" @click="$store.state.reportForming.previewMode = true">
              <svg xmlns="http://www.w3.org/2000/svg" width="30.135" height="15.511" viewBox="0 0 30.135 15.511">
                <g id="Group_9806" data-name="Group 9806" transform="translate(-6901.266 -1412.416)">
                    <path id="Path_561" data-name="Path 561" d="M6930.778,1420.174a18.007,18.007,0,0,1-28.888,0,18,18,0,0,1,28.888,0Z" transform="translate(-0.001)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                    <circle id="Ellipse_33" data-name="Ellipse 33" cx="3.747" cy="3.747" r="3.747" transform="translate(6912.586 1416.425)" fill="none" stroke="#28345c" stroke-miterlimit="10" stroke-width="1"/>
                </g>
                </svg>
          </div>
      </div>
      
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'FormReportButtons',
    props:{
        activeForm:{
            type: Boolean,
        },
        activeFormed:{
            type: Boolean,
        },
        formType:{
            type: String,
        },
        report:{
            type: Object,
        },

    },
    data(){
        return{
        }
    },
    computed: {
        reportStatusActive() {
            if (this.formType == 'periodic') {
                let active = true;
                if (this.report.data.periodicReportEmails.length == 0) {
                    active = false;
                }   
                else if (this.report.data.periodicReportTiming.date == null || this.report.data.periodicReportTiming.formEvery == 0) {
                    active = false;
                }
                else if (this.report.data.reportData.length > 0 && this.report.data.reportData[0].cols && this.report.data.reportData[0].cols.length < 3) {
                    active = false;
                }
                else if (this.report.data.selectedShownColumns ? this.report.data.selectedShownColumns.length == 0 : false) {
                    active = false;
                }
                return active
            }
            else {
                return true;
            }
        }     
    },
    methods:{
        formReport(){
            if(!this.activeForm) return;
            let report_data = {};
            this.$store.state.reportForming.progress = 0;
            if(this.formType == 'momentary'){
                report_data = this.$store.state.reportForming.momentaryReport;
            }
            else if(this.formType == 'periodic'){

                report_data = {
                    'id': this.report.id,
                    'folder_id': this.$store.state.reportForming.selectedFolder,
                    'title': this.report.name,
                    'periodicReportTiming': this.report.data.periodicReportTiming,
                    'reportList': this.report.data.reportData,
                    'emails': this.report.data.periodicReportEmails,
                    'shownColumns': this.report.data.selectedShownColumns
   
                }
            }
            this.$store.state.reportForming.reportIsForming = true;
             axios.post(
                `${this.$store.state.api_link}/report-templates/form-report`,
                {
                    report: report_data,
                },
                this.$store.getters.axiosConfig()
            ).then((response) => {
        
            });
        },
        reportAction(reportType){
            if(reportType == 'momentary'){
                axios.post(
                    `${this.$store.state.api_link}/report-templates/momentary/download`,
                    {
                        path: this.$store.state.reportForming.reportUrl
                    },
                    this.$store.getters.axiosConfigDownload()
                ).then((response) => {
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(response.data);
                    a.download = 'Report.xlsx';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                });
            }
            else {
                if(this.reportStatusActive)
                    this.$set(this.report, 'isActive', !this.report.isActive);
            }
        }
      
    }
}
</script>

<style lang="scss" scoped>
.button-container{
    display: flex;
    flex-direction: column;
    // width: 30%;
    place-self: end;
    margin-top: 1rem;
    &.active{

    }
}
.row{
    display: flex;
    flex-direction: row;
}
.btn{
    display: flex;
    align-items: center;
    padding: 6px 20px;
    background: white;
    color: var(--primary-color);
    border-radius: 0.5rem;
    font-size: clamp(16px, 1vw, 18px);
    margin: 0.25rem;
    width: 10rem;
    font-size: 14px;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background-color: #4D4D4D;
        color: white;
    }
    svg{
        margin-left: 12px;
    }
    &.deactivated{
        background: #F7F7F7;
        color: var(--primary-color-opacity-50);
       
        &.icon{
            svg{
                path, circle{
                    stroke: var(--primary-color-opacity-50);
                }
            }
        }
        &.secondary{
            svg{
                path{
                    stroke:var(--primary-color-opacity-50);
                }
            }
        }
        &.active{
            color: var(--primary-color);
            &.secondary{
                svg{
                    path{
                        stroke:var(--primary-color);
                    }
                }
            }
        }
        &:hover{
            &.secondary{
                color: var(--primary-color-opacity-50);
                svg{
                    path{
                        stroke:var(--primary-color-opacity-50);
                    }
                }
            }
        }
    }
    &.active{
        background-color: #49B257;
        &.secondary{
            background-color: #49B257;
        }
    }
    &.icon{
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.25rem;
        svg{
             margin-left: 0px;
            path, circle{
                stroke: var(--primary-color);
            }
        }
    }
    &.secondary{
        background-color: var(--secondary-color);
        padding: 6px 20px;
        &:hover{
            background-color: #4D4D4D;
            color: white;
            &.deactivated {
                background-color: var(--secondary-color);
                color: var(--primary-color-opacity-50);
            }
            &.active{
                background-color: #49B257;
                color: var(--primary-color);
                svg{
                    path{
                        stroke:var(--primary-color);
                    }
                }
            }
            svg{
                path{
                    stroke:white;
                }
            }
        }
    }
    span{
        margin-left: 5px;
    }
}
</style>