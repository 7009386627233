<template>
  <div class="main-container" v-if="isLoading == false">
    <div class="top-row">
      <div class="search-box">
        <span
          >{{ toLocal("user_side.assigned_trainings") }}
          {{ trainings.length }}</span
        >
        <div class="input-container">
          <input
            type="text"
            :placeholder="toLocal('generic.search')"
            v-model="searchInput"
            @input="searchTrainings"
            :class="{ placeholder: searchInput == '' }"
          />
          <TrainingSearchSVG v-if="searchInput == ''" class="search-icon" />
        </div>
      </div>
      <div class="end-box">
        <div class="categories">
          <span>{{ toLocal("users.show_categories") }}</span>
          <CustomDropdown
            class="c-dropdown users-info-dropdown"
            :list="trainingGroups"
            :compare-list="shownCategories"
            :options="{
              checkbox: true,
              selectAllChoice: toLocal('users.many_folders'),
              closeOnClick: false,
              initial: toLocal('generic.choose'),
            }"
            @elClicked="toggleCategoryFilter"
            @elAll="toggleAllCategories"
          />
        </div>
        <div class="sort">
          <span>{{ toLocal("user_side.sort_by") }}</span>
          <CustomDropdown
            class="c-dropdown users-info-dropdown"
            :list="sortList"
            :compare-chosen="chosenSort"
            :options="{
              checkbox: false,
              initial: chosenSort.name
                ? chosenSort.name
                : toLocal('user_side.sort_by'),
            }"
            @elClicked="sortBy"
          />
        </div>
      </div>
    </div>
    <div class="trainings">
      <div
        v-for="training in trainings"
        :key="`training-${training.id}`"
        class="single-training"
        :class="{'has-stat': training.last_training_stat != null}"
      >
      <div class="last-stat" v-if="training.last_training_stat">
          Paskutinis apsilankymas: {{training.last_training_stat.created_at ? training.last_training_stat.created_at.split('T')[0] : ''}}
      </div>
        <div class="training-cover">
          <img
            :src="
              getFileUrl(training.cover)
                ? getFileUrl(training.cover)
                : './img/pia21974.0.17df4049.jpg'
            "
            onerror="this.src='./img/pia21974.0.17df4049.jpg'"
            :alt="training.title"
          />
        </div>
        <div class="training-info">
          <div class="training-top">
            <span class="title"> {{ training.title }}</span>
            <span class="folder">{{
              training.training_groups[0]
                ? training.training_groups[0].name
                : toLocal("general.other_trainings")
            }}</span>
          </div>
          <div class="training-bottom">
            <div class="bottom-part-1">
              <div class="completion-status">
                <span>{{ toLocal("users.training_complete") }}:</span>
                <span>{{
                  training.last_training_end_stat != null
                    ? (training.last_training_end_stat.created_at.split(" ")[0].split('T')[0])
                    : "-"
                }}</span>
                <span
                  class="label"
                  :class="getTrainingLabel(training, 'class')"
                >
                  <BlockSVG
                    v-if="getTrainingLabel(training, 'class') == 'gray'"
                  />
                  <PlaySVG
                    v-else-if="getTrainingLabel(training, 'class') == 'yellow'"
                  />
                  <PauseSVG
                    v-else-if="getTrainingLabel(training, 'class') == 'teal'"
                  />
                  <CheckSVG
                    v-else-if="getTrainingLabel(training, 'class') == 'green'"
                  />
                  <CrossSVG
                    v-else-if="getTrainingLabel(training, 'class') == 'red'"
                  />
                  {{ getTrainingLabel(training) }}

                  <!-- {{
                training.last_training_stat == null
                  ? toLocal("reports.training_not_started")
                  : training.last_training_end_stat != null
                  ? toLocal("reports.training_completed")
                  : toLocal("reports.training_unfinished")
              }} -->
                </span>
              </div>
              <div class="test-status">
                <span
                  >{{ toLocal("user_trainings.test_passed") }}
                  {{
                    training.last_test_stat_end != null
                      ? training.last_test_stat_end.created_at.split(" ")[0].split("T")[0]
                      : "⚊"
                  }}</span
                >
                <span
                  >{{ toLocal("user_side.test_result") }}
                  {{
                    training.last_test_stat_end
                      ? Math.round(
                          training.last_test_stat_end.training_result * 100,
                          2
                        ) + " %"
                      : "⚊"
                  }}
                  <!-- <EyeSVG v-if="training.last_test_stat_end" class="eye-icon"
                /> -->
                </span>
                <span class="label" :class="getTestLabel(training, 'class')">
                  <BlockSVG v-if="getTestLabel(training, 'class') == 'gray'" />
                  <PlaySVG
                    v-else-if="getTestLabel(training, 'class') == 'yellow'"
                  />
                  <PauseSVG
                    v-else-if="getTestLabel(training, 'class') == 'teal'"
                  />
                  <CheckSVG
                    v-else-if="getTestLabel(training, 'class') == 'green'"
                  />
                  <CrossSVG
                    v-else-if="getTestLabel(training, 'class') == 'red'"
                  />

                  {{ getTestLabel(training) }}</span
                >
              </div>
              <div class="cert-image">
                <img
                  :src="
                    training.certificate
                      ? $store.state.backend_link +
                        training.certificate.cert_path
                      : require(`@/assets/images/placeholder_cert.png`)
                  "
                />
              </div>
            </div>
            <div class="bottom-part-2">
              <div class="certificate-status">
                <div class="cert-info">
                  <span>{{ toLocal("users.certificate_valid") }}:</span>
                  <span>{{
                    training.certificate
                      ? training.certificate.expiration_date &&
                        training.certificate.expires == 1
                        ? training.certificate.expiration_date
                        : toLocal("generic.indefinite")
                      : "⚊"
                  }}</span>
                  <span
                    class="label"
                    :class="getCertificateLabel(training, 'class')"
                  >
                    <BlockSVG
                      v-if="getCertificateLabel(training, 'class') == 'gray'"
                    />
                    <CheckSVG
                      v-else-if="
                        getCertificateLabel(training, 'class') == 'green'
                      "
                    />
                    <CrossSVG
                      v-else-if="
                        getCertificateLabel(training, 'class') == 'red'
                      "
                    />

                    {{ getCertificateLabel(training) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import CustomDropdown from "../../elements/CustomDropdown.vue";
import EyeSVG from "@/assets/images/icons/users/eye-icon.svg?inline";
import TrainingSearchSVG from "@/assets/images/icons/users/search.svg?inline";
import BlockSVG from "@/assets/images/icons/users/block.svg?inline";
import CheckSVG from "@/assets/images/icons/users/check.svg?inline";
import PauseSVG from "@/assets/images/icons/users/pause.svg?inline";
import PlaySVG from "@/assets/images/icons/users/play.svg?inline";
import CrossSVG from "@/assets/images/icons/users/cross.svg?inline";
export default {
  name: "UserTrainings",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    EyeSVG,
    CustomDropdown,
    TrainingSearchSVG,
    BlockSVG,
    CheckSVG,
    PauseSVG,
    PlaySVG,
    CrossSVG,
  },
  data: function () {
    return {
      trainings: [],
      trainingCopy: [],
      shownCategories: [],
      isLoading: false,
      trainingGroups: [],
      searchInput: "",
      sortList: [
        { id: 1, name: this.toLocal("users.sort_name_first") },
        { id: 2, name: this.toLocal("users.sort_name_last") },
        { id: 3, name: this.toLocal("users.sort_visit_date")+' ↑' },
        { id: 4, name: this.toLocal("users.sort_visit_date")+' ↓' },
        { id: 5, name: this.toLocal("users.sort_completion_date_first") },
        { id: 6, name: this.toLocal("users.sort_completion_date_last") },
        { id: 7, name: this.toLocal("users.sort_result_first") },
        { id: 8, name: this.toLocal("users.sort_result_last") },
        { id: 9, name: this.toLocal("users.sort_cert_first") },
        { id: 10, name: this.toLocal("users.sort_cert_last") },
      ],
      chosenSort: { id: 1, name: this.toLocal("users.sort_name_first") },
    };
  },
  mounted() {
    this.loadTrainings(this.user.id);
  },
  methods: {
    toggleAllCategories(isAll) {
      if (isAll) {
        this.shownCategories = [];
      } else {
        this.shownCategories = [];
        this.trainingGroups.forEach((group) => {
          this.shownCategories.push({ id: group.id, name: group.name });
        });
      }
    },
    toggleCategoryFilter(id) {
      if (this.shownCategories.find((el) => el.id == id)) {
        this.shownCategories.splice(
          this.shownCategories.findIndex((x) => x.id == id),
          1
        );
      } else {
        this.shownCategories.push({ id: id });
      }
    },
    sortBy(id) {
      this.chosenSort =
        this.sortList[this.sortList.findIndex((x) => x.id == id)];
      this.sortTrainings();
    },
    searchTrainings(e) {
      if (e.target.value != "") {
        this.trainings = this.trainingsCopy.filter((el) => {
          return el.title.toLowerCase().includes(e.target.value.toLowerCase());
        });
      } else {
        this.trainings = _.cloneDeep(this.trainingsCopy);
      }
      this.chosenSort = this.sortList[0];
      this.shownCategories = _.cloneDeep(this.trainingGroups);
    },
    loadTrainings(id) {
      this.isLoading = true;
      axios
        .post(
          `${this.$store.state.api_link}/user/trainings/detailed`,
          { user_id: id },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status != "error") {
            this.trainings = response.data.data.trainings;
            this.trainingGroups = response.data.data.training_groups;
            this.shownCategories = _.clone(response.data.data.training_groups);
            this.trainingsCopy = response.data.data.trainings;
            // this.filteredTrainings = this._.cloneDeep(this.trainings);
            // this.filteredTrainingsCopy = this._.cloneDeep(
            //   this.filteredTrainings
            // );
            // this.sortTrainings();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error getting info");
        });
    },
    getTrainingLabel(training, type = null) {
      if (type == "class") {
        if (!training.hasTrainings) {
          return "gray";
        } else if (training.last_training_stat == null) {
          return "yellow";
        } else if (training.last_training_end_stat != null) {
          return "green";
        } else {
          return "teal";
        }
      } else {
        if (!training.hasTrainings) {
          return this.toLocal("users.training_doesnt_exist");
        } else if (training.last_training_stat == null) {
          return this.toLocal("reports.training_not_started");
        } else if (training.last_training_end_stat != null) {
          return this.toLocal("reports.training_completed");
        } else {
          return this.toLocal("reports.training_unfinished");
        }
      }
    },
    getTestLabel(training, type = null) {
      if (type == "class") {
        if (training.hasTest) {
          if (
            training.last_test_stat_start == null &&
            training.last_test_stat_end == null
          ) {
            return "yellow";
          } else if (
            training.last_test_stat_start != null &&
            training.last_test_stat_end == null
          )
            return "teal";
          else if (
            training.last_test_stat_start != null &&
            training.last_test_stat_end != null
          ) {
            if (training.last_test_passed) return "green";
            else return "red";
          }
        } else return "gray";
      } else {
        if (training.hasTest) {
          if (
            training.last_test_stat_start == null &&
            training.last_test_stat_end == null
          ) {
            return this.toLocal("reports.test_not_started");
          } else if (
            training.last_test_stat_start != null &&
            training.last_test_stat_end == null
          )
            return this.toLocal("user_side.test_incomplete");
          else if (
            training.last_test_stat_start != null &&
            training.last_test_stat_end != null
          ) {
            if (training.last_test_passed)
              return this.toLocal("user_side.test_passed");
            else return this.toLocal("user_side.test_failed");
          }
        } else return this.toLocal("user_side.test_doesnt_exist");
      }
    },
    getCertificateLabel(training, type = null) {
      if (type == "class") {
        if (!training.certificate) {
          return "gray";
        } else if (training.certificate.expires == 1) {
          if (training.certificate.expired == 1) {
            return "red";
          } else {
            return "green";
          }
        } else {
          return "green";
        }
      } else {
        if (!training.certificate) {
          return this.toLocal("users.cert_doesnt_exist");
        } else if (training.certificate.expires == 1) {
          if (training.certificate.expired == 1) {
            return this.toLocal("user_side.certificate_invalid");
          } else {
            return this.toLocal("user_side.certificate_valid");
          }
        } else {
          return this.toLocal("user_side.certificate_valid");
        }
      }
    },
    sortTrainings() {
      let sortID = this.chosenSort.id;
      switch (sortID) {
        case 1:
          this.trainings = _.orderBy(this.trainings, ["title"], ["asc"]);
          break;
        case 2:
          this.trainings = _.orderBy(this.trainings, ["title"], ["desc"]);
          break;
        case 3:
          this.trainings = _.orderBy(this.trainings, ["last_training_stat.created_at"], ["asc"]);
          break;
        case 4:
          this.trainings = _.orderBy(this.trainings, ["last_training_stat.created_at"], ["desc"]);
          break;
        case 5:
          this.trainings = _.orderBy(
            this.trainings,
            ["last_training_end_stat_date"],
            ["desc"]
          );
          break;
        case 6:
          this.trainings = _.orderBy(
            this.trainings,
            ["last_training_end_stat_date"],
            ["asc"]
          );
          break;
        case 7:
          this.trainings = _.orderBy(
            this.trainings,
            ["last_test_stat_end.created_at"],
            ["asc"]
          );
          break;
        case 8:
          this.trainings = _.orderBy(
            this.trainings,
            ["last_test_stat_end.created_at"],
            ["desc"]
          );
        case 9:
          this.trainings = _.orderBy(
            this.trainings,
            ["certificate.expiration_date"],
            ["asc"]
          );
          break;
        case 10:
          this.trainings = _.orderBy(
            this.trainings,
            ["certificate.expiration_date"],
            ["desc"]
          );
          break;

        default:
          break;
      }
    },
    filterCategories() {
      this.trainings = this.trainingsCopy.filter((training) => {
        return (
          training.training_groups[0] &&
          this.shownCategories.find(
            (el) => el.id == training.training_groups[0].id
          )
        );
      });

      if (this.chosenSort != "") {
        this.sortTrainings();
      }
    },
  },
  watch: {
    shownCategories: {
      handler() {
        if (this.searchInput == "") {
          this.filterCategories();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.top-row {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  .search-box {
    display: flex;
    flex-direction: column;
    font-size: clamp(14px, 1.5vw, 16px);
    gap: 8px;
    input {
      padding: 4px 8px;
      border-radius: 44px;
      border: 1px solid #b2b2b2;
      box-sizing: border-box;
      &.placeholder {
        padding-left: 31px;
      }
    }
  }
  .end-box {
    display: flex;
    gap: 56px;
    font-size: clamp(14px, 1.5vw, 16px);
    width: 50%;
    justify-content: flex-end;
    .categories {
      display: flex;
      flex-direction: column;
    }
    .sort {
      display: flex;
      flex-direction: column;
    }
  }
}
.input-container {
  position: relative;
  display: flex;
  align-items: center;
  .search-icon {
    position: absolute;
    left: 8px;
  }
}
.trainings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 60vh;
  overflow-y: auto;
  margin-top: 16px;
  padding-bottom: 16px;
  .single-training {
    position: relative;
    width: 100%;
    background-color: #f4f4f4;
    border-radius: 12px;
    display: flex;
    gap: 20px;
    padding: 20px;
    &.has-stat{
      padding-top: 34px;
    }
    .last-stat{
      position: absolute;
      top: 7px;
      color: #4D4D4D;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      font-family: var(--main-site-font);
    }
  }
}
.training-cover {
  width: 126px;
  height: 126px;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
.training-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .training-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    gap: 16px;
    border-bottom: 1px solid var(--secondary-color);
    .title {
      font-size: clamp(18px, 1.5vw, 20px);
      width: 80%;
    }
    .folder {
      font-size: clamp(10px, 1.5vw, 12px);
      flex-wrap: wrap;
      display: flex;
      justify-content: flex-start;
      min-width: 15%;
      max-height: 37px;
      overflow-y: hidden;
      width: 0%;
      word-break: break-all;
      flex-grow: 1;
      align-items: center;
      max-width: 250px;
    }
  }
  .training-bottom {
    display: flex;
  }
}
.training-bottom {
  display: flex;
  justify-content: space-between;
  font-size: clamp(14px, 1.5vw, 16px);
  padding-top: 12px;
  gap: 16px;
  .bottom-part-1 {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    .cert-image {
      width: 108px;
      height: 76px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
  .completion-status {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 30%;
  }
  .test-status {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 30%;
    span {
      display: flex;
      align-items: center;
    }
    .eye-icon {
      cursor: pointer;
      transition: 0.25s;
      margin-left: 8px;
      &:hover path {
        fill: var(--primary-color);
      }
    }
  }
  .bottom-part-2 {
    flex-grow: 1;
  }
  .certificate-status {
    display: flex;
    .cert-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
  }
}
.c-dropdown {
  background-color: var(--secondary-color);
  border-radius: 6px;
  color: white;
  padding: 4px 10px;
  border: none;
  .dropdown-initial{
    color: white;
  }
}
.categories {
  width: 55%;
  gap: 8px;
}
.sort {
  gap: 8px;
  width: 45%;
}

.label {
  padding: 2px 8px;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
}
.gray {
  background: #cacaca;
}
.yellow {
  background-color: #f3de18;
}
.teal {
  background: #a2dfec;
}
.green {
  background: #85e091;
}
.red {
  background-color: #ff7f7f;
}
</style>
<style lang="scss">
.users-info-dropdown{
  &.dropdown-container{
    .dropdown-initial{
      color: white;
    }
  }
  .checkmark{
    path{
      stroke: white;
    }
  }
}
</style>