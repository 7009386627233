<template>
  <section name="training">
    <!-- <TestInfoModal 
      :isOpen="infoModalOpen"
      :test="modalTest"
      @closeModal="infoModalOpen = false"
    /> -->
    <div
      v-if="$store.state.previewMode && !isMobileWindow() &&  (training ? training.trainingType != 'attest' : true)"
      class="preview--header"
    >
      <span>
        {{ toLocal("training.preview_mode") }}
        <TrainingViewSvg />
      </span>
      <div class="exit--preview" @click="exitPreviewMode">
        <TrainingSideCloseSvg />
      </div>
    </div>
    <div
      class="background--image"
      :style="{ 'background-image': `url(${getFileUrl(training.cover)})` }"
    />
    <div class="background--cover" />
   
      <TrainingSideNavigation v-if="currentTopic != -1 && !testHasStarted"  :training="training" :scrolledTopic="scrolledTopic" @gotoTopic="goToTopicFromMenu(null, $event, 'raw')"/>
    <div
      v-if="currentTopic == -1"
      :class="{
        'container main--container training': true,
        'side--open': $store.getters.isSideOpen(),
      }"
      ref="mainContainer"
      :style="{
        'max-width': customSysStyle && customSysStyle.training_contents_style ? customSysStyle.training_contents_style['max-width'] : '' 
      }"
    >
      <div class="training--landing">
        <div class="training--landing--inner">
          <h2 
            v-if="training.time != 0" 
            class="training--time"
            :style="{
              'font-size': customSysStyle && customSysStyle.training_contents_style && customSysStyle.training_contents_style.title ? customSysStyle.training_contents_style.title['font-size'] + 'px' : '',
              'font-weight': customSysStyle && customSysStyle.training_contents_style && customSysStyle.training_contents_style.title ? customSysStyle.training_contents_style.title['font-weight'] : '',
            }"
          >
            {{ training.time }} min
          </h2>
          <h2
            :style="{
              'font-size': customSysStyle && customSysStyle.training_contents_style && customSysStyle.training_contents_style.title ? customSysStyle.training_contents_style.title['font-size'] + 'px' : '',
              'font-weight': customSysStyle && customSysStyle.training_contents_style && customSysStyle.training_contents_style.title ? customSysStyle.training_contents_style.title['font-weight'] : '',
            }"
          >{{ training.title }}</h2>
          <!-- <p class="desc" v-html="training.description" /> -->
          <editor
            :isEditing="false"
            :readOnly="true"
            v-model="training.description"
          />
          <div class="start--button left--button" @click="goBack()">
            {{ toLocal("generic.back") }}
          </div>
          <div
            v-if="training.content && training.content.length > 0"
            class="start--button"
            @click="startTraining()"
          >
            {{ toLocal("training.start") }}
          </div>
          <h3 v-if="training.content && training.content.length > 0">
            {{ toLocal("training.content") }}
          </h3>
          <TrainingContent
            v-if="training.content"
            :items="training.content"
            text_label="title"
            @action="goToTopicFromMenu"
          />
        </div>
      </div>
    </div>
    <div v-if="currentTopic >= 0">
      <div
        v-for="(topic, topicIndex) in training.content"
        :key="topicIndex"
        :id="topicIndex"
        v-show="
          showTest
            ? topic.test ? 
              topic.test && showTest : false
            : topic.test ? 
              false : true
        "
      >
        <div
          v-if="currentTopic >= topicIndex"
          :id="`topic-${topicIndex}`"
          class="training"
        >
          <div
            :ref="`training-${topicIndex}`"
            class="training--header"
            v-if="!topic.test"
          >
            <div class="training--header--inner">
              <div
                class="go-back-to-start"
                @click="goBackToStart"
                v-if="topicIndex == 0"
              >
                <ArrowLeftSvg />{{ toLocal("generic.back") }}
              </div>
              <div class="training--header--number">
                {{ topicIndex + 1 }}
              </div>
              <h2
                :style="[
                  { fontSize: topic.title.length > 30 ? '23px' : '45px' },
                ]"
              >
                {{ topic.title }}
              </h2>
            </div>
          </div>
          <div
            v-else-if="topic.test && testHasStarted && !topic.test_done"
            class="test--header"
          >
           
            <div class="questions" v-if="training.trainingType=='attest'">
              {{ toLocal("training_edit.questions_given") }} - {{ topic.items.length }}
            </div>
            <div class="questions" v-else>
              {{ toLocal("generic.question") }} -
              {{ topic.currentquestion + 1 }}/{{ topic.items.length }}
            </div>
            <div
              class="time"
              v-if="
                topicTimers[topicIndex]
                  ? topicTimers[topicIndex].initial_time != 0
                  : false
              "
            >
              {{toLocal('generic.time_left_label')}} {{ topicTimers[topicIndex].counter }} min
            </div>
          </div>
          <div
            :ref="`training-${topicIndex}`"
            class="user--header"
            v-else
          ></div>
          <div
            v-if="!topic.test"
            :class="[
              'container main--container training',
              { 'side--open': $store.getters.isSideOpen() },
            ]"
            ref="mainContainer"
             :style="{
              'max-width': customSysStyle && customSysStyle.general ? customSysStyle.general['width']+'%' : '' 
            }"
          >
            <div class="training--block">
              <div
                v-for="(trainingItem, trainingItemIndex) in topic.items"
                :key="`trainingItem-${topicIndex}-${trainingItemIndex}`"
                :class="[
                  'content--item--wrapper',
                  trainingItem.half ? 'half--item' : '',
                  isLeftHalf(topicIndex, trainingItemIndex)
                    ? 'left--item'
                    : 'right--item',
                ]"
              >
                <div
                  :class="[
                    'content--item',
                    'content--item--' + trainingItem.type,
                    hideTopPadding(topicIndex, trainingItemIndex)
                      ? 'top--padding--hidden'
                      : '',
                    hideBottomPadding(topicIndex, trainingItemIndex)
                      ? 'bottom--padding--hidden'
                      : '',
                  ]"
                  v-if="showItem(topic, trainingItemIndex)"
                >
                  <!-- Text item -->
                  <!-- <div 
                    v-if="trainingItem.type == 'text'"
                    :class="h2Centered(topicIndex, trainingItemIndex) ? 'h2--centered' : ''"
                    v-html="trainingItem.content"
                  /> -->
                  <editor
                    class="text-item"
                    v-if="
                      trainingItem.type == 'text' &&
                      (trainingItem.content != '' ||
                        (trainingItem.title != '' &&
                          trainingItem.title != '<h2></h2>' &&
                          trainingItem.title != '<h2></br></h2>'))
                    "
                    :class="
                      h2Centered(topicIndex, trainingItemIndex)
                        ? 'h2--centered'
                        : ''
                    "
                    :readOnly="true"
                    :item="trainingItem"
                    :value="
                      trainingItem.title
                        ? trainingItem.title + trainingItem.content
                        : trainingItem.content
                    "
                  />

                  <!-- Media item -->
                  <TrainingItem
                    v-if="
                      ['video', 'url', 'doc', 'img', 'scorm'].includes(trainingItem.type)
                    "
                    :key="trainingItem.id"
                    :training="training"
                    :trainingItem="trainingItem"
                    :topic_index="topicIndex"
                    :item_index="trainingItemIndex"
                    :readOnly="true"
                    :videoTests="videoTests"
                    :videoSubtitles="videoSubtitles"
                    :videoTestSettings="videoTestSettings"
                    @videoEnded="videoEnded(trainingItem)"
                  />

                  <!-- Seperator item -->
                  <div
                    v-if="trainingItem.type == 'seperator'"
                    class="seperator"
                  >
                    <div class="seperator--top" />
                    <div class="seperator--bottom" />
                  </div>
                  <!-- Confirm Task -->
                  <ConfirmTask
                    v-if="trainingItem.type == 'confirm'"
                    :item="trainingItem"
                    :topic="topic"
                    :training_key="training_key"
                    :readOnly="true"
                  />

                  <!-- Task item -->
                  <div v-if="trainingItem.type == 'task'" class="task">
                    <editor
                      :isEditing="false"
                      :item="trainingItem"
                      :readOnly="true"
                      :value="trainingItem.content"
                    />
                    <div v-if="getTaskType(topicIndex, trainingItemIndex) == 1">
                      <div class="task--answers">
                        <div
                          v-for="(answer, answerIndex) in trainingItem.answers"
                          :key="answer.text+'-'+answerIndex"
                          :class="[
                            'task--answer',
                            { selected: answer.selected },
                            {
                              correct:
                                answer.correct && trainingItem.result == 1,
                            },
                          ]"
                          @click="selectTaskAnswer(trainingItem, answerIndex)"
                        >
                          <div :class="['task--answer--letter']">
                            {{ String.fromCharCode(65 + answerIndex) }}
                          </div>
                          <div class="task--answer--text" v-html="convertToHyperlinks(answer.text)">
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!trainingItem.result"
                        class="task--check"
                        @click="checkTask(trainingItem)"
                      >
                        {{ toLocal("generic.check") }}
                      </div>
                    </div>
                    <div v-if="getTaskType(topicIndex, trainingItemIndex) == 2">
                      <div class="task--images">
                        <div
                          v-for="(answer, answerIndex) in trainingItem.answers"
                          :key="answer.image"
                          :class="[
                            'task--image',
                            { selected: answer.selected },
                            {
                              correct:
                                answer.correct && trainingItem.result == 1,
                            },
                          ]"
                          @click="selectTaskAnswer(trainingItem, answerIndex)"
                        >
                          <div :class="['task--answer--letter']">
                            {{ String.fromCharCode(65 + answerIndex) }}
                          </div>
                          <div class="answer-image">
                      
                            <NewImageEditor
                   
                              :readOnly="true"
                              :item="answer"
                              type="cover"
                              :isEditing="false"
                              :height="200"
                              :width="200"
                              :src="getFileUrl(answer.image)"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!trainingItem.result"
                        class="task--check"
                        @click="checkTask(trainingItem)"
                      >
                        {{ toLocal("generic.check") }}
                      </div>
                    </div>
                    <div v-if="getTaskType(topicIndex, trainingItemIndex) == 3">
                      <div class="task--connects task--connects--part1" >
                        <draggable
                          v-model="trainingItem.selected"
                          class="task--connects--list"
                          :class="{connected: trainingItem.result}"
                          :options="{
                            disabled: trainingItem.result,
                            swap: true,
                            animation: 200,
                            group: 'description',
                            ghostClass: 'ghost',
                          }"
                          :move="
                            (event) =>
                              handleMove(event, topicIndex, trainingItemIndex)
                          "
                          animation="200"
                          @end="handleDragEnd(topicIndex, trainingItemIndex)"
                        >
                          <transition-group
                            type="transition"
                            :name="'flip-list'"
                          >
                            <div
                              v-for="answer in trainingItem.selected"
                              :key="answer.part1"
                              :class="['task--connect']"
                            >
                              <div
                                :class="[
                                  'task--connect--part1',
                                  {
                                    'task--connect--correct':
                                      trainingItem.result && !answer.incorrect,
                                  },
                                ]"
                              >
                                <div
                                  :class="[
                                    'task--connect--temp',
                                    {
                                      'task--connect--temp--active':
                                        answer.temptext,
                                    },
                                  ]"
                                >
                                  <div>
                                    {{ answer.temptext }}
                                  </div>
                                </div>
                                <div>
                                  {{ answer.part1 }}
                                </div>
                              </div>
                            </div>
                          </transition-group>
                        </draggable>
                      </div>
                      <div class="task--connects task--connects--part2">
                        <div
                          v-for="answer in trainingItem.answers"
                          :key="answer.part2"
                          :class="['task--connect']"
                        >
                          <div
                            :class="[
                              'task--connect--part2',
                              {
                                'task--connect--correct':
                                  trainingItem.result && !answer.incorrect,
                              },
                            ]"
                          >
                            <div>
                              {{ answer.part2 }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!trainingItem.result"
                        class="task--check"
                        @click="checkTask(trainingItem)"
                      >
                        {{ toLocal("training.connect") }}
                      </div>
                    </div>
                   <TestTaskResults v-if="trainingItem.result" :item="trainingItem" />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                showEndButton &&
                (currentTopic == topicIndex) &&
                canProgress(topic)
              "
              class="end--button"
              @click="endTraining()"
            >
              {{ toLocal("training.end") }}
            </div>
            <div
              v-if="
                !showEndButton &&
                (currentTopic == topicIndex) &&
                canProgress(topic)
              "
              class="end--button last-topic-btn"
              @click="nextTopic(topic)"
            >
              {{ training_prop && lastShownTopic == topic.id ? 'Grįžti į pirkimo langą' : toLocal("generic.next") }}
            </div>
          </div>

          <TrainingTestUser
            v-if="topic.test && showTest"
            class="test-topic"
            :ref="`testTopic-${topicIndex}`"
            :topic="topic"
            :training="training"
            :topicIndex="topicIndex"
            :showEndButton="showEndButton"
            :user_id="user_id"
            :showingEnding="showingEnding"
            :showTest="showTest"
            :currentTopic="currentTopic"
            @nextTopic="nextTopic(topic)"
            @endTraining="endTraining"
            @endTestTraining="endTrainingTimer"
            @retakeTraining="retakeTraining"
            @testStarted="
              setTestTimer(topic, topicIndex);
              testHasStarted = true;
            "
            @stopTimer="stopTestTimer(topicIndex)"
          />
        </div>
      </div>
      <div v-if="showingEnding && videoTestResultsShown == false" class="training training--end">
        <div :ref="`training-end`" class="training--header">
          <div class="training--header--inner">
            <div class="training--header--number">
              {{ training.trainingType == 'attest' ? 1 : training.content.length + 1 }}
            </div>
            <h2>
              {{  training.trainingType == 'attest' ? toLocal('tests.attest_end_label') :  toLocal("training.ending") }}
            </h2>
          </div>
        </div>
        <div
          :class="{
            'container main--container training': true,
            'side--open': $store.getters.isSideOpen(),
          }"
          ref="mainContainer"
          :style="{
            'max-width': customSysStyle && customSysStyle.general ? customSysStyle.general['width']+'%' : '' 
          }"
        >
          <button @click="showTestResults()" v-if="testsWithAnswers && testsWithAnswers.some(e=>e.is_test_passed == true)" class="test-answers-btn">{{toLocal('generic.test_answers')}} <EyeSvg stroke="#333333" stroke-width="1.25" fill="none"/></button>
          <div class="vtest-ending" v-if="videoTests && videoTests.length != 0 && training.certificate.enabled">
            <span class="end--title" v-if="this.testsWithAnswers.every(test=>test.is_test_passed)">
              <b>{{toLocal('test.training_passed')}}</b><br>{{toLocal('test.certificate_where')}}
            </span>
            <span class="end--title" v-else><b>{{toLocal('test.made_mistake')}}</b><br>
                {{toLocal('test.certificate_req')}}</span>
          </div>
          <span class="end--title big" v-else>
            {{ training.trainingType == 'attest' ? toLocal('tests.attesting_done') : training.endingTitle}}
          </span>
          <div class="end--description">
            {{
              training.endingDescription != "..."
                ? training.endingDescription
                : ""
            }}
          </div>
          <div class="end--rating">
            <div class="rate-text"  v-if="training.trainingType == 'attest'">
              {{ toLocal('tests.rate_label') }}
            </div>
            <div
              v-for="star in 5"
              :key="star"
              :ref="`rating-star-${star}`"
              :class="[
                'end--rating--star',
                hoveredStar >= star || starRating >= star ? 'active' : '',
              ]"
              :style="{
                'background-image':
                  hoveredStar >= star || starRating >= star
                    ? 'url(' +
                      require('@/assets/images/icons/star-active.svg') +
                      ')'
                    : 'url(' + require('@/assets/images/icons/star.svg') + ')',
              }"
              @mouseover="hoverStar(star)"
              @mouseout="unhoverStar()"
              @click="ratingStar(star)"
            />
            <br />
            {{ training.trainingType == 'attest' ? '' : toLocal("training.rating_question") }}
          </div>
          <textarea
            ref="commentInput"
            class="comment--input"
            :placeholder="toLocal('training_stats.comment')"
            v-model="commentText"
            max-length="200"
          />
          <transition name="fade">
            <div v-if="commentText.length > 0" class="comment-save-button" @click="saveComment">
              <span>
              {{isSavingComment || savedComment ? '' : toLocal('generic.send_label')}} 
                <font-awesome-icon v-if="isSavingComment && !savedComment" icon="spinner" />
                <svg v-if="savedComment" xmlns="http://www.w3.org/2000/svg" width="21.657" height="20.823" viewBox="0 0 21.657 20.823">
                  <path id="Path_5921" data-name="Path 5921" d="M-371.547-14977.008a1.887,1.887,0,0,1-1.522-.775l-6.685-9.178a1.883,1.883,0,0,1,.413-2.631,1.882,1.882,0,0,1,2.631.416l5.083,6.975,9.711-14.783a1.887,1.887,0,0,1,2.608-.539,1.882,1.882,0,0,1,.54,2.607l-11.205,17.059a1.893,1.893,0,0,1-1.529.85Z" transform="translate(380.115 14997.831)" fill="#28345c"/>
                </svg>
              </span>
            </div>
          </transition>
          <!-- <div 
            v-if="showEndButton" 
            class="end--button"
            @click="endTraining()"
          >
            {{ toLocal('training.end') }}
          </div> -->
          <!-- <div 
            v-if="showEndButton && topicHasTest" 
            class="end--button"
            @click="startTest();"
          >
            Spręsti testą
          </div> -->
          <div
            v-if="showEndButton && !topicHasTest && canProgress(topic)"
            class="end--button"
            @click="endTraining()"
          >
            {{ toLocal("training.end") }}
          </div>
          <div
            v-if="showEndButton && topicHasTest && canProgress(topic)"
            class="end--button"
            @click="endTraining()"
          >
            {{ training.trainingType == 'attest' ? toLocal('tests.attest_end_button_label') : toLocal("training.end") }}
          </div>

          <div
            v-if="!showEndButton && topic.test && testPassed "
            class="end--button"
            @click="nextTopic()"
          >
            {{ toLocal("training.go_to_start") }}
          </div>
          <div
            v-else-if="!showEndButton && topic.test && !testPassed"
            class="end--button"
            @click="restartTraining()"
          >
            {{ toLocal("training.try_again") }}
          </div>
          <div v-if="!showEndButton && canProgress(topic)" class="end--button next-btn-generic" @click="nextTopic()">
            {{ toLocal("generic.next") }}
          </div>
          <div class="end--button--container" v-if="showEndButton && training.trainingType != 'attest'">
            <div class="btn restart" v-if="!training_key"  @click="restartTraining">
              <TrainingRestartSvg />
              <span>
                {{ toLocal("training.try_again") }}
              </span>
            </div>
            <div v-if="!training_key" class="btn go-back" @click="endTraining">
              <TrainingGoBackSvg />
              <span>
                {{ toLocal("training.go_to_start") }}
              </span>
            </div>
          </div>
          <div class="end--button--secondary--container"></div>
        </div>
      </div>
      <div v-else-if="showingEnding && videoTestResultsShown" class="training training--end vtest">
        <div :ref="`training-end`" class="training--header">
          <div class="training--header--inner">
            <div class="training--header--number">
              {{ training.content.length + 1 }}
            </div>
            <h2>
              {{toLocal('test.video_answers')}}
            </h2>
          </div>
        </div>
        <div class="vtest-results-container">
          <button @click="videoTestResultsShown = false" class="test-answers-btn"><ArrowLeftSvg stroke="black" stroke-width="2" stroke-linecap="round"/>{{toLocal('generic.back')}}</button>
          <div class="answers-container">
            <div class="test-container" v-for="test,index in testsWithAnswers" :key="`vtest-${index}`" v-show="test.is_test_passed">
              <span class="test-title">{{toLocal('test.test')}} {{index+1}}</span>
              <div class="single-answer-box" v-for="question, qIndex in test.questions" :key="`vtest-answer-${qIndex}`">
                <span class="question-title">{{question.question}}</span>
                <div class="answers-inner">
                  <span class="single-answer" :class="[{correct: answer.correct}]" v-for="answer, index in question.answers" :key="`answers-${index}`">
                    <span class="letter">{{ String.fromCharCode(65 + index).toUpperCase() }}</span>{{ answer.text }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    <GlobalModal
      v-if="showInvitationExpiredModal"
      :modalInfo="{
        type: 'info',
        show_button: true,
        text: 'modal.training_expired_text',
        confirmBtnText: 'save_modal.ok',
      }"
      @onConfirm="showInvitationExpiredModal = false;$router.push('/login');$store.state.loading.loadingDone()"
      @onClose="showInvitationExpiredModal = false; $router.push('/login');$store.state.loading.loadingDone()"
    />
    <portal-target name="vtest-results-container"></portal-target>
  </section>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import VueScrollTo from "vue-scrollto";
import moment from "moment";
import TrainingItem from "@/components/layout/TrainingItem";
import Editor from "@/components/layout/trainings/training_components/Editor.vue";
import TrainingTestUser from "@/components/layout/trainings/training_components/test/TestUser/TrainingTestUser.vue";
import TestInfoModal from "@/components/layout/trainings/training_components/test/TestUser/TestInfoModal.vue";
import ConfirmTask from "@/components/layout/trainings/training_components/ConfirmTask.vue";
import ImageViewer from "@/components/layout/trainings/training_components/ImageViewer.vue";
import TrainingContent from "@/components/layout/trainings/training_components/TrainingContent.vue";
import TrainingSideNavigation from "@/components/layout/trainings/training_components/TrainingSideNavigation.vue";
import GlobalModal from "@/components/layout/modals/GlobalModal.vue";
import DOMPurify from 'dompurify';

//SVG COMPONENTS
import TrainingViewSvg from "@/assets/images/icons/training/training-view.svg?inline";
import TrainingSideCloseSvg from "@/assets/images/icons/side-close.svg?inline";
import TrainingTaskCorrectSvg from "@/assets/images/icons/task-correct.svg?inline";
import TrainingTaskIncorrectSvg from "@/assets/images/icons/task-incorrect.svg?inline";
import TrainingRestartSvg from "@/assets/images/icons/training/training-restart.svg?inline";
import TrainingGoBackSvg from "@/assets/images/icons/training/training-go-back.svg?inline";
import NewImageEditor from "@/components/layout/trainings/training_components/ImageEditor/NewImageEditor.vue";
import TestTaskResults from "@/components/layout/trainings/training_components/TestTaskResults.vue";
import ArrowLeftSvg from "@/assets/images/icons/homeEdit/arrow-go-back-16-8.svg?inline";
import EyeSvg from "@/assets/images/eye.svg?inline";

export default {
  name: "Training",
  components: {
    draggable,
    TrainingItem,
    Editor,
    ImageViewer,
    TrainingTestUser,
    TestInfoModal,
    ConfirmTask,
    NewImageEditor,
    TrainingContent,
    TrainingSideNavigation,
    GlobalModal,
    TestTaskResults,

    //SVG
    TrainingViewSvg,
    TrainingSideCloseSvg,
    TrainingTaskCorrectSvg,
    TrainingTaskIncorrectSvg,
    TrainingRestartSvg,
    TrainingGoBackSvg,
    ArrowLeftSvg,
    EyeSvg
  },
  props:{
    training_prop: {
      type: Object,
      default: null
    },
    current_topic_prop:{
      type: Number,
      default: -1
    },
  },
  data: function () {
    return {
      currentTopic: -1,
      showingEnding: false,
      showEndButton: false,
      hoveredStar: 0,
      starRating: 0,
      scrolledTopic: -1,
      navbarInterval: null,
      prevRoute: null,
      commentText: '',
      training: {
        title: "-",
        time: "-",
        description: "-",
        endingEnabled: true,
        endingDescription: "-",
        content: [
          {
            title: "-",
            items: [
              {
                type: "text",
                content: "<h2>-</h2><br>-",
                editing: false,
              },
            ],
          },
        ],
      },
      videoTests: [],
      videoSubtitles: [],
      videoTestSettings: [],
      testResults: 0,
      testsWithAnswers: [],
      topicHasTest: false,
      hasStartedTraining: false,
      hasEnded: false,
      showTest: false,
      testPassed: false,
      testHasStarted: false,
      testHasEnded: false,
      trainingInfo: {},
      infoModalOpen: false,
      modalTest: {},
      topicTimers: [],
      topicTimerObj: [],
      end_stat: null,
      user_id: null,
      showInvitationExpiredModal:false,
      videoTestResultsShown: false,
      finishedAnswers: [],
      isSavingComment: '',
      savedComment:false,
      canContinue: false,
      sessionToken: '',
      endStatFormData: null,
    };
  },
  watch: {
    currentTopic() {
      if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage(
          "currentTopic:" + this.currentTopic
        );
        if(this.currentTopic != -1 && !this.training_prop){
          setTimeout(()=>{
            history.replaceState(undefined, undefined, "#"+this.currentTopic)
          },300)
        }
    },
   
    showingEnding(){
      if(!this.hasEnded && this.showingEnding){
        if(this.training_key)
          this.sendPostMessage({ type: "training_end", user_id: this.user_id, training_id: this.$store.state.currentTraining, date: new Date().toISOString(), timestamp: Date.now()})
        if (this.training.trainingType != 'attest') {
          axios
            .post(
              `${this.$store.state.api_link}/training/stats/end`,
              {
                training: this.$store.state.currentTraining,
                result: this.testResults,
                hasTest: this.topicHasTest,
                is_rented: this.$store.state.currentTrainingRented,
                key: this.training_key,
              },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              if (response.data.status == "success") {
                this.hasEnded = true;
              } else {
                console.log("Error while sending statistics");
              }
            })
            .catch(() => {
              this.hasEnded = false;
              console.log("Error while sending statistics");
            });
        }
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed:{
    training_key(){
      return this.$route.params.key
    },
    lastShownTopic(){
      if(this.training_prop && this.training_prop.selectedShownTopics){
        return this.training_prop.selectedShownTopics[this.training_prop.selectedShownTopics.length-1];
      }
      else return null;
    },
    attestTest() {
      if (this.training.trainingType == 'attest') {
        return this.training.content ? this.training.content.find((el) => el.test == true) : null;
      }
      else{
        return null;
      }
    }
    // isTestAnswersShown(){

    // }
  },
  mounted(){
    
    if(!this.$store.state.header_hidden) this.$store.state.header_hidden = true
    if(this.$store.state.loading.hide){
      this.$store.state.loading.loadingStart();
    }
    window.addEventListener("scroll", this.handleScroll.bind(this));
    if(this.$route.query.id && this.$store.state.currentTraining == -1){
      this.$store.state.currentTraining = this.$route.query.id
      if(this.$route.query.rented == 1){
        this.$store.state.currentTrainingRented = true
      }
    }
    if(!this.training_prop){
      if (this.$store.state.currentTrainingRented == true) {
        axios
          .get(
            `${this.$store.state.api_link}/training/info/rented/${this.$store.state.currentTraining}`,
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.trainingInfo = JSON.parse(response.data.data);
            if (response.data.training_users) {
              this.$set(this.trainingInfo,'training_users', response.data.training_users);
            }
            this.end_stat = response.data.end_stat;
            this.loadTraining(this.trainingInfo);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let training_id = this.training_key != undefined ? this.training_key : this.$store.state.currentTraining;
        let has_key = this.training_key != undefined ? true : false;
        if(this.$route.meta.keyed){
          training_id = this.$route.params.key;
        }
        if(training_id == -1){
          this.$router.push('/');
        }else{
          let training_request_link = `${this.$store.state.api_link}/training/info/${training_id}${has_key ? this.$route.meta && this.$route.meta.keyed  ? '/temp-keyed' : '/keyed' : ''}${this.$store.state.isTrainingBought ? '?paid=true' : ''}`
          axios
          .get(
            training_request_link,
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if(has_key){
              this.user_id = response.data.user_id;
              this.$store.state.currentTraining = response.data.training_id;
            }
            this.trainingInfo = JSON.parse(response.data.data);
            if (response.data.training_users) {
              this.$set(this.trainingInfo,'training_users', response.data.training_users);
            }
            this.videoTests = response.data.video_tests;
            let tempList = [];
            if(typeof this.videoTests == 'object'){
              Object.keys(this.videoTests).forEach((key)=>{
                tempList.push(this.videoTests[key]);
              })
            }
            this.videoTests = tempList;
            this.videoTestSettings = response.data.video_test_settings;
            this.end_stat = response.data.end_stat;
            this.loadTraining(this.trainingInfo);
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data && error.response.data.code != undefined && [0,1].includes(error.response.data.code)){
              this.showInvitationExpiredModal = true;
            }
            console.log(error);
          });
        }
      }
    }
    else{
      this.$store.state.currentTraining = this.training_prop.id;
      this.trainingInfo = this._.cloneDeep(this.training_prop);
      this.end_stat = this.training_prop.end_stat;
      this.loadTraining(this.trainingInfo);
    }
    this.loadForFiles();
    // }

    window.scrollTo(0, 0);
    let vm = this;
    if (window.ReactNativeWebView) {
      window.addEventListener("message", this.setWebViewData); //android
      document.addEventListener("message", this.setWebViewData); //ios
    }
    this.$root.$on("goBackTraining", this.goBackArrow);
    this.navbarInterval = setInterval(() => {
      this.handleNavbar();
    }, 100);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
    if (this.navbarInterval != null) {
      clearInterval(this.navbarInterval);
      this.navbarInterval = null;
    }
    if (window.Echo){
      window.Echo.leave(`${this.$store.state.system_settings.lms_key}.training.${this.training.id}`);
    }
    this.saveUserTime('end', this.training.id);
    this.training.content.forEach((topic, topicIndex) => {
      if (topic.test && !topic.test_done && this.testHasStarted) {
        let test = this.$refs[`testTopic-${topicIndex}`];
        if (test) {
          if (Array.isArray(test)) test = test[0];
          test.checkTest(this.training.content[topicIndex], topicIndex);
        }
      }
    });
    this.training = [];
    this.endStatFormData = null;
    this.$store.state.trainingMainContainer = null
    window.removeEventListener("beforeunload", this.leavePageTest);
    window.removeEventListener('beforeunload', this.sendData)
    window.removeEventListener('beforeunload', this.sendBeacon)
    document.removeEventListener("visibilitychange", this.sendVisibilityBeacon);
    window.removeEventListener("message", this.setWebViewData);
    document.removeEventListener("message", this.setWebViewData);
    this.$store.state.isTrainingBought=false;
    this.$root.$off("goBackTraining", this.goBackArrow);
  },

  methods: {
    startEchoListen() {
      if(window.Echo != null){
        var channel = window.Echo.channel(`presence-${this.$store.state.system_settings.lms_key}.training.${this.$store.state.currentTraining}`);
        let id = this.$store.state.currentTraining;
        channel.on('pusher:subscription_succeeded',(member) => {
          this.saveUserTime('start',id);
        })
      }
    },
    saveUserTime(state, training_id){
      let key = null;
      if(this.$route.meta.keyed){
        key = this.$route.params.key;
      }
      axios.post(`${this.$store.state.api_link}/users/save-timestamp`, 
            {training:training_id, type: "training", state: state, key:key, token: this.sessionToken},
            this.$store.getters.axiosConfig())
            .then((res)=>{
              if(res.data.status == 'success' && res.data.token){
                this.sessionToken = res.data.token;
              }
            })
    },
    loadForFiles(){
      axios.post(`${this.$store.state.api_link}/load/subtitle`, 
            {training:this.$store.state.currentTraining}
        , this.$store.getters.axiosConfig()).then((response)=>{
          if(response.data.subtitles){
            this.videoSubtitles = response.data.subtitles;
          }

      })
    },

    videoEnded(item) {
      this.$set(item, 'video_ended', true);
      this.canContinue = true;
    },
    showTestResults(){
      this.videoTestResultsShown = true;
    },
    goBackToStart() {
      this.hasStartedTraining = false;
      this.currentTopic = -1;
    },
    setWebViewData(data) {
      if (data.data.startsWith("topic:")) {
        this.currentTopic = parseInt(data.data.split(":")[1]);
      }
      if (data.data.startsWith("fileExists:")) {
        let file = data.data.split(":")[1];
        if(!this.$store.state.existingDeviceFiles.includes(file)) this.$store.state.existingDeviceFiles.push(file);
      }
      else if(data.data.startsWith("fileNotExists:")) {
        let file = data.data.split(":")[1];
        window.ReactNativeWebView.postMessage("downloadFile:" + this.$store.state.api_uploads_link+file);
      }
    },
    sendData(){
      this.saveUserTime('end',this.training.id)
    },
    goBackArrow() {
      if (this.currentTopic != -1) {
        this.currentTopic = -1;
      } else {
        this.$router.go(-1);
      }
    },
     finishTraining(){
      if(this.training_key)
          this.sendPostMessage({ type: "training_end", user_id: this.user_id, training_id: this.$store.state.currentTraining, date: new Date().toISOString(), timestamp: Date.now()})
       if (this.training.trainingType != 'attest') {
         axios
           .post(
             `${this.$store.state.api_link}/training/stats/end`,
             {
               training: this.$store.state.currentTraining,
               result: this.testResults,
               hasTest: this.topicHasTest,
               is_rented: this.$store.state.currentTrainingRented,
               key: this.training_key,
             },
             this.$store.getters.axiosConfig()
           )
           .then((response) => {
             if (response.data.status == "success") {
               this.hasEnded = true;
             } else {
               console.log("Error while sending statistics");
             }
           })
           .catch(() => {
             this.hasEnded = false;
             console.log("Error while sending statistics");
           });
       }
    },
    loadTraining(training_data) {
      let training = training_data;
      // this.startEchoListen();
      // if(window.Echo == null){
      this.saveUserTime('start',this.$store.state.currentTraining);
      training.content.forEach((topic) => {
        topic.items.forEach((item) => {
          if (item.type == "text")
            item.content = item.content
              .replace("<h2></h2>", "")
              .replace("<p></p>", "");
          if (item.type == "task") {
            if (item.answers) {
              item.answers.forEach((answer) => {
                this.$set(answer, "selected", false);
              });
            }
            if (item.taskType == 3) {
              if (item.answers) {
                let selected = JSON.parse(JSON.stringify(item.answers));
                for (let i = selected.length - 1; i > 0; i--) {
                  const j = Math.floor(Math.random() * i);
                  const temp = selected[i];
                  selected[i] = selected[j];
                  selected[j] = temp;
                }
                this.$set(item, "selected", selected);
              }
            }
          }
        });
        if (topic.test) {
          this.$set(topic, "currentquestion", 0);
          topic.items.forEach((item) => {
            item.answers.forEach((answer) => {
              this.$set(answer, "selected", false);
            });
            if (item.type == 3) {
              let selected = JSON.parse(JSON.stringify(item.answers));
              for (let i = selected.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * i);
                const temp = selected[i];
                selected[i] = selected[j];
                selected[j] = temp;
              }
              this.$set(item, "selected", selected);
            }
          });
          this.topicHasTest = true;
        }
      });
      training.content = training.content.filter(function (e) {
        return e.enabled;
      });
      this.training = training;
      this.$set(this.training, 'id', this.$store.state.currentTraining)
      this.beforeUnloadSaveStats();
      this.showingEnding = false;
      this.currentTopic = -1;
      this.$nextTick(() => {
        if (Array.isArray(this.$refs.mainContainer))
          this.$store.state.trainingMainContainer = this.$refs.mainContainer[0]
        else this.$store.state.trainingMainContainer = this.$refs.mainContainer;
      })
      if(this.current_topic_prop != -1){
        if(this.training.content[this.current_topic_prop] && this.training.content[this.current_topic_prop].test){
          this.showTest = true;
        }
        this.currentTopic = parseInt(this.current_topic_prop);
        this.gotoRaw(this.current_topic_prop);
      }
      if(window.location.hash != undefined && window.location.hash != null && window.location.hash.length >0){
        let hash = window.location.hash.replace('#','');
        if(parseInt(hash) > (this.training.content.length - 1)){
          this.currentTopic = this.training.content.length -1;
        }
        else if(parseInt(hash) < 0){
          this.currentTopic = 0;
        }
        else{
          this.currentTopic = parseInt(hash);
        }
        if(this.training.content[this.currentTopic] && this.training.content[this.currentTopic].test){
          this.showTest = true;
          this.gotoTest(this.currentTopic);
        }
        else{
          
          this.gotoRaw(this.currentTopic);
        }
        this.startTrainingRequest();
      }
      if (this.training.trainingType == 'attest' && this.currentTopic <0) {
        let test_index = this.training.content.findIndex((el) => el.test == true);
        if (test_index != -1) {
          this.currentTopic = test_index;
          if(this.training.content[this.currentTopic] && this.training.content[this.currentTopic].test){
            this.showTest = true;
            this.gotoTest(this.currentTopic);
          }
        }
      }
      this.starRating = 0;
      this.$store.state.loading.loadingDone();
      if (this.$store.state.previewTopic >= 0) {
        if (this.training.content[this.$store.state.previewTopic].test) {
          this.gotoTest(this.$store.state.previewTopic);
        } else {
          this.goto(this.$store.state.previewTopic);
        }
      } else if (
        this.$store.state.previewTopic == 0 &&
        this.training.content.length == 1 &&
        this.training.content[this.$store.state.previewTopic].test
      ) {
        this.gotoTest(this.$store.state.previewTopic);
      }
    },
    startTrainingRequest() {
      if (this.training.trainingType == 'attest') return;
      axios
          .post(
            `${this.$store.state.api_link}/training/stats/start`,
            {
              training: this.$store.state.currentTraining,
              is_rented: this.$store.state.currentTrainingRented,
              key: this.training_key,
            },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            this.handleScroll();
            if (response.data.status == "success") {
               this.hasStartedTraining = true;
            } else {
              console.log("Error while sending statistics");
            }
          })
          .catch(() => {
            console.log("Error while sending statistics");
          });
    },
    beforeUnloadSaveStats(){
      if (navigator.sendBeacon) {
        let key = null;
        if(this.$route.meta.keyed){
          key = this.$route.params.key;
        }
        this.endStatFormData = new FormData();
        this.endStatFormData.append("type", "training");
        this.endStatFormData.append("training", this.training.id);
        this.endStatFormData.append("state", 'end');
        if(key != null)
          this.endStatFormData.append("key", key);
        document.addEventListener("visibilitychange", this.sendVisibilityBeacon);
        window.addEventListener('beforeunload', this.sendBeacon);
      } else {
        window.addEventListener('beforeunload', this.sendData)
      }
    },
    sendVisibilityBeacon(){
      if (document.visibilityState === "hidden") {
        this.endStatFormData.set("token", this.sessionToken);
        navigator.sendBeacon(`${this.$store.state.api_link}/users/save-timestamp`, this.endStatFormData);
      }
    },
    sendBeacon(){
      this.endStatFormData.set("token", this.sessionToken);
      navigator.sendBeacon(`${this.$store.state.api_link}/users/save-timestamp`, this.endStatFormData);
    },
    retakeTraining() {
      this.currentTopic = 0;
      this.showTest = false;
      this.hasEnded = false;
      this.showingEnding = false;
      this.showEndButton = false;
      this.loadTraining(this.trainingInfo);
    },
    leavePageTest(e) {
      e.preventDefault();
      e.returnValue = this.toLocal("training.test_incomplete");
      return this.toLocal("training.test_incomplete");
    },
    startTraining() {
      this.currentTopic = 0;
      window.scrollTo(0, 0);
      this.checkForEnd();
      if(this.training_key != undefined)
        this.sendPostMessage({ type: "training_start", user_id: this.user_id, training_id: this.$store.state.currentTraining, date: new Date().toISOString(), timestamp: Date.now()})
      if ((this.training_key != undefined && this.$route.meta.keyed) || this.training_key == undefined) {
        if (this.training.trainingType != 'attest') {
          axios
            .post(
              `${this.$store.state.api_link}/training/stats/start`,
              {
                training: this.$store.state.currentTraining,
                is_rented: this.$store.state.currentTrainingRented,
                key: this.training_key,
              },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              this.handleScroll();
              if (response.data.status == "success") {
                this.hasStartedTraining = true;
              } else {
                console.log("Error while sending statistics");
              }
            })
            .catch(() => {
              console.log("Error while sending statistics");
            });
        }
      }
      if (
        this.currentTopic == 0 &&
        this.training.content.length == 1 &&
        this.training.content[this.currentTopic].test
      ) {
        this.gotoTest(this.currentTopic);
      }
      this.hasStartedTraining = true;
    },
    isLeftHalf(topicIndex, itemIndex) {
      if (
        this.training.content[topicIndex] &&
        this.training.content[topicIndex].items[itemIndex] &&
        this.training.content[topicIndex].items[itemIndex].half
      ) {
        let halves = 0;
        for (
          let i = 0;
          i < this.training.content[topicIndex].items.length;
          i++
        ) {
          if (i == itemIndex) {
            return halves % 2 == 0;
          }
          const item = this.training.content[topicIndex].items[i];
          if (item && item.half) {
            halves++;
          }
        }
      }
      return false;
    },
    hideTopPadding(topicIndex, itemIndex) {
      if (itemIndex == 0) return false;
      if (this.training.content[topicIndex].items[itemIndex].half) {
        let offset = 1;
        if (!this.isLeftHalf(topicIndex, itemIndex)) offset = 2;
        if (!this.training.content[topicIndex].items[itemIndex - offset])
          return false;
        if (this.training.content[topicIndex].items[itemIndex - offset].half) {
          if (
            this.training.content[topicIndex].items[itemIndex - offset].type ==
              "text" ||
            this.training.content[topicIndex].items[itemIndex - offset - 1]
              .type == "text"
          ) {
            return true;
          }
        } else {
          if (
            this.training.content[topicIndex].items[itemIndex - offset].type ==
            "text"
          ) {
            return true;
          }
        }
      } else if (this.training.content[topicIndex].items[itemIndex - 1].half) {
        if (
          this.training.content[topicIndex].items[itemIndex - 1].type == "text"
        )
          return true;
        if (
          itemIndex > 1 &&
          this.training.content[topicIndex].items[itemIndex - 2].type == "text"
        )
          return true;
      } else {
        if (
          this.training.content[topicIndex].items[itemIndex - 1].type == "text"
        )
          return true;
      }
      return false;
    },
    hideBottomPadding(topicIndex, itemIndex) {
      if (itemIndex == this.training.content[topicIndex].items.length - 1)
        return false;
      if (this.training.content[topicIndex].items[itemIndex].half) {
        let offset = 2;
        if (!this.isLeftHalf(topicIndex, itemIndex)) offset = 1;
        if (!this.training.content[topicIndex].items[itemIndex + offset])
          return false;
        if (this.training.content[topicIndex].items[itemIndex + offset].half) {
          if (
            this.training.content[topicIndex].items[itemIndex + offset].type ==
              "text" ||
            this.training.content[topicIndex].items[itemIndex + offset + 1]
              .type == "text"
          ) {
            return true;
          }
        } else {
          if (
            this.training.content[topicIndex].items[itemIndex + offset].type ==
            "text"
          ) {
            return true;
          }
        }
      } else if (this.training.content[topicIndex].items[itemIndex + 1].half) {
        if (
          this.training.content[topicIndex].items[itemIndex + 1].type == "text"
        )
          return true;
        if (
          itemIndex < this.training.content[topicIndex].items.length - 2 &&
          this.training.content[topicIndex].items[itemIndex + 2].type == "text"
        )
          return true;
      } else {
        if (
          this.training.content[topicIndex].items[itemIndex + 1].type == "text"
        )
          return true;
      }
      return false;
    },
    h2Centered(topicIndex, itemIndex) {
      if (
        this.training.content[topicIndex].items[itemIndex].half &&
        this.training.content[topicIndex].items[itemIndex + 1]
      ) {
        if (
          this.isLeftHalf(topicIndex, itemIndex) &&
          this.training.content[topicIndex].items[itemIndex + 1].type == "url"
        ) {
          return true;
        }
        if (
          !this.isLeftHalf(topicIndex, itemIndex) &&
          this.training.content[topicIndex].items[itemIndex - 1].type == "url"
        ) {
          return true;
        }
      }
      return false;
    },
    openUrl(itemIndex) {
      let url =
        this.training.content[this.currentTopic].items[itemIndex].content.split(
          "§"
        )[1];
      let win = window.open(url, "_blank");
      win.focus();
    },
    getDocumentUrl(content) {
      let url = content.split("§")[0];
      if (!url.endsWith("pdf") || window.outerWidth <= 768)
        // https://docs.google.com/viewerng/viewer?url=
        url =
          "https://docs.google.com/gview?url=" +
          url +
          "&embedded=true&time=" +
          Math.floor(Math.random() * 100000);
      // url = "https://view.officeapps.live.com/op/embed.aspx?src=" + url;
      return url;
    },
    getDocumentDownloadUrl(content) {
      return content
        .split("§")[0]
        .replace("uploads", "api/download")
        .replace("placeholders", "api/download");
    },
    getDocumentName(content) {
      return content.split("§")[1];
    },
    getDocumentEnabled(content) {
      return content.split("§")[2] == "1";
    },
    downloadDocument(content) {
      axios
        .post(
          `${this.$store.state.api_link}/training/stats/download-file`,
          {
            training: this.$store.state.currentTraining,
            file: this.getDocumentUrl(content),
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
          } else {
            console.log("Error while sending statistics");
          }
        })
        .catch(() => {
          console.log("Error while sending statistics");
        });
    },
    isNativeVideo(item) {
      return !item.content.startsWith("iframe");
    },
    getVideoUrl(content) {
      return content.split("§")[1];
    },
    getTaskType(topic, index) {
      if (this.training.content[topic])
        return this.training.content[topic].items[index].taskType;
      return -1;
    },
    selectTaskAnswer(task, answerIndex) {
      if (task.result) return;
      if (!task.answers[answerIndex].selected)
        this.$set(task.answers[answerIndex], "selected", true);
      else
        task.answers[answerIndex].selected =
          !task.answers[answerIndex].selected;
    },
    handleDragEnd(topicIndex, trainingItemIndex) {
      this.futureItem =
        this.training.content[topicIndex].items[trainingItemIndex].selected[
          this.futureIndex
        ];
      this.movingItem =
        this.training.content[topicIndex].items[trainingItemIndex].selected[
          this.movingIndex
        ];
      this.futureItem.temptext = null;
      this.movingItem.temptext = null;
      const _items = Object.assign(
        [],
        this.training.content[topicIndex].items[trainingItemIndex].selected
      );
      _items[this.futureIndex] = this.movingItem;
      _items[this.movingIndex] = this.futureItem;

      this.training.content[topicIndex].items[trainingItemIndex].selected =
        _items;
    },
    handleMove(e, topicIndex, trainingItemIndex) {
      const { index, futureIndex } = e.draggedContext;
      this.movingIndex = index;
      this.futureIndex = futureIndex;
      this.training.content[topicIndex].items[
        trainingItemIndex
      ].selected.forEach((answer, answerIndex) => {
        if (answerIndex == this.futureIndex) {
          this.$set(
            answer,
            "temptext",
            this.training.content[topicIndex].items[trainingItemIndex].selected[
              index
            ].part1
          );
        } else if (answerIndex == this.movingIndex) {
          this.$set(answer, "temptext", null);
          // this.$set(answer, 'temptext', this.training.content[topicIndex].items[trainingItemIndex].selected[this.futureIndex].part1);
        } else {
          this.$set(answer, "temptext", null);
        }
      });
      return false; // disable sort
    },
    checkTask(task) {
      let correct = true;
      let comment = "";
      this.$set(task, 'can_progress', true);
      if (task.comment_tab == 0) {
        comment = task.comment_main;
        if (task.comment_main_file) {
          this.$set(task, "comment_file", task.comment_main_file);
        }
      }
      if (task.taskType == 3) {
        task.selected.forEach((selected, selectedIndex) => {
          let answer = task.answers[selectedIndex];
          if (
            answer.part1 != selected.part1 ||
            answer.part2 != selected.part2
          ) {
            correct = false;
            selected.incorrect = true;
            answer.incorrect = true;
          }
        });

        if (task.comment_tab == 1) {
          if (correct) comment = task.comment_correct;
          else comment = task.comment_incorrect;
        }
        this.$set(task, "result", correct ? 1 : 2);
        this.$set(task, "result_text", comment);
        return;
      }
      task.answers.forEach((answer) => {
        if (
          (answer.correct && !answer.selected) ||
          (!answer.correct && answer.selected)
        ) {
          correct = false;
          if (task.comment_tab == 2) {
            if(answer.comment)
              comment += answer.comment + " ";
            if (answer.file) {
              this.$set(task, "comment_file", answer.file);
            }
          }
        }
      });
      if (task.comment_tab == 1) {
        if (correct) {
          comment = task.comment_correct
            ? task.comment_correct
            : this.toLocal("test.congratulations");
          if (task.comment_correct_file) {
            this.$set(task, "comment_file", task.comment_correct_file);
          }
        }
        else {
          comment = task.comment_incorrect
            ? task.comment_incorrect
            : this.toLocal("test.incorrect_picked");
          if (task.comment_incorrect_file) {
            this.$set(task, "comment_file", task.comment_incorrect_file);
          }
        }
      }


      if (correct) {
        if (task.comment_tab == 2) {
          task.answers.forEach((answer) => {
            if (answer.correct) comment = answer.comment;
            if (answer.file) {
              this.$set(task, "comment_file", answer.file);
            }
          });
        }
        this.$set(task, "result", 1);
      } else {
        if(!task.answers.some(answer => answer.correct)) {
          this.$set(task, "result", 3);
        }
        else {
          this.$set(task, "result", 2);
        }
      }
      if(comment && comment.length > 0)
        this.$set(task, "result_text", comment);
    },
    startTest() {
      let test_index = this.training.content.findIndex((el) => el.test == true);
      if (test_index == -1) return;
      let test_topic = this.training.content[test_index];
      this.modalTest = test_topic;
      this.infoModalOpen = test_topic.test_done ? false : true;
      let test_id = test_topic.version_id;
      this.showTest = true;
      setTimeout(() => {
        let vm = this;
        VueScrollTo.scrollTo(`#topic-${test_index}`, 500, {
          onDone: function (element) {
            vm.showEndButton = false;
            vm.showingEnding = false;
          },
        });
      }, 50);
      if (!test_topic.test_done) {
        // axios.post(
        //     `${this.$store.state.api_link}/training/stats/start/test`,
        //     {training_id: this.$store.state.currentTraining, test_id: test_id},
        //     this.$store.getters.axiosConfig()
        // ).then((response) => {
        //   this.testHasStarted = true;
        //   window.addEventListener("beforeunload", this.leavePageTest)
        // })
        // .catch(() => {
        //   console.log("Error while sending statistics");
        // });
      }
    },

    getCertificate() {
      axios
        .post(
          `${this.$store.state.api_link}/certificates/attach`,
          {
            cert_id: this.training.certificate.background,
            training_id: this.$store.state.currentTraining,
            training_title: this.training.title,
            expiration_date: this.training.certificate.expiration,
            expires: this.training.certificate.expires,
            user_id: this.training.selectedUser ? this.training.selectedUser : null,
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.new_cert == 1) {
              let cert = this.$store.state.certificates.siteCertificates.find(
                (el) => el.id == this.training.certificate.background
              );
              let imgURL = "";
              if (cert != null) {
                imgURL = `${this.$store.state.api_assets_link}/${cert.path}`;
              }
              this.$store.state.toast.showPopup(
                {
                  img: imgURL,
                  title: this.toLocal("training.congrats"),
                  message: this.toLocal("training.got_cert"),
                },
                3000
              );
              this.$store.state.certificates.appendCertificate(
                response.data.certificate
              );
            } else {
              this.$store.state.certificates.updateCertificate(
                response.data.certificate
              );
            }
          }
        })
        .catch((err) => {});
    },
    hasMargin(topicIndex, itemIndex) {
      if (!this.$refs[`trainingImage-${topicIndex}-${itemIndex}`]) {
        return false;
      }
      let img = this.$refs[`trainingImage-${topicIndex}-${itemIndex}`][0];
      if (!img) return false;
    },
    nextTopic(current_topic) {
      if(this.training_prop && current_topic && this.lastShownTopic == current_topic.id){
          this.$emit('goBack');
      }
      else{
        this.canContinue = false;
        this.goto(this.currentTopic + 1);
      }
    },
    totalTopicTime(topic) {
      var content = this.training.content;

      var time = 0;
      if (!this.overallTimer[topic])
        this.overallTimer[topic] = { topic: "", time: 0, type: "" };
      var currentTopic = parseInt(topic);
      var timer = setInterval(() => {
        if (topic == currentTopic) {
          time += 1;
          this.overallTimer[currentTopic].time = time;
        }
      }, 1000);
      this.overallTimer[currentTopic].topic = currentTopic;
      this.overallTimer[currentTopic].type = content[currentTopic].test
        ? "test"
        : "topic";
      this.overallTimerObj[currentTopic] = timer;
    },
    stopTotalTopicTimer(topicIndex) {
      clearInterval(this.overallTimerObj[topicIndex]);
    },
    goto(index) {
      if (
        this.currentTopic != this.training.content.length - 1 &&
        this.training.content[this.currentTopic + 1].test
      ){
        this.showTest = true;
        this.finishTraining();
      }
      if (this.currentTopic == this.training.content.length - 1) {
        this.showingEnding = true;
      }
      this.currentTopic = parseInt(index);
      if (!this.hasStartedTraining) {
        if (this.training.trainingType != 'attest') {
          axios
            .post(
              `${this.$store.state.api_link}/training/stats/start`,
              {
                training: this.$store.state.currentTraining,
                is_rented: this.$store.state.currentTrainingRented,
                key: this.training_key,
              },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              this.handleScroll();
              if (response.data.status == "success") {
                this.hasStartedTraining = true;
              } else {
                console.log("Error while sending statistics");
              }
            })
            .catch(() => {
              console.log("Error while sending statistics");
            });
        }
      }
      this.checkForEnd();

      if (this.$refs["video"] != undefined)
        this.$refs["video"].forEach((element) => {
          element.pause();
        });
      if (this.$refs["audio"] != undefined)
        this.$refs["audio"].forEach((element) => {
          element.pause();
        });

      setTimeout(() => {
        if (this.showingEnding) {
          VueScrollTo.scrollTo(`.training--end`, 500);
        } else {
          VueScrollTo.scrollTo(`#topic-${this.currentTopic}`, 500);
        }
        // window.scrollTo({
        //   top: offsetY,
        //   behavior: 'smooth',
        // });
      }, 200);
      this.hasStartedTraining = true;
    },
    gotoTest(index) {
      if (
        this.currentTopic == this.training.content.length - 1 ||
        (this.training.content[this.currentTopic + 1] &&
          this.training.content[this.currentTopic + 1].test &&
          !this.showTest)
      )
        this.showingEnding = true;
      this.currentTopic = parseInt(index);
      if (!this.hasStartedTraining) {
        if (this.training.trainingType != 'attest') {
          axios
            .post(
              `${this.$store.state.api_link}/training/stats/start`,
              {
                training: this.$store.state.currentTraining,
                is_rented: this.$store.state.currentTrainingRented,
                key: this.training_key,
              },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              this.handleScroll();
              if (response.data.status == "success") {
                this.hasStartedTraining = true;
              } else {
                console.log("Error while sending statistics");
              }
            })
            .catch(() => {
              console.log("Error while sending statistics");
            });
        }
      }
      this.checkForEnd();

      if (this.$refs["video"] != undefined) {
        this.$refs["video"].forEach((element) => {
          element.pause();
        });
      }
      if (this.$refs["audio"] != undefined) {
        this.$refs["audio"].forEach((element) => {
          element.pause();
        });
      }
      this.startTest();
    },
    gotoRaw(index) {
      if (!this.canProgress(this.training.content[this.currentTopic])) return;
  
      let showingEnding = false;
      if (
        this.training.content[index] &&
        this.training.content[index].test &&
        !this.showTest
      ) {
        showingEnding = true;
        this.gotoTest(index);
        return;
      }
      if(
        this.training.content[index] &&
        !this.training.content[index].test &&
        this.showTest &&
        !this.testHasStarted
      ){
        this.showTest = false;
      }
      this.currentTopic = parseInt(index);
      this.checkForEnd();
      this.$nextTick(() => {
        if (showingEnding) {
          VueScrollTo.scrollTo(`.training--end`, 100);
        } else {
          VueScrollTo.scrollTo(`#topic-${index}`, 100);
        }
      });
    },
    getItemUrl(url) {
      if (url.indexOf("http") == -1 || url.indexOf("www") == -1)
        url = this.$store.state.api_uploads_link + url;
      return url;
    },
    canProgress(topic) {
      if (!topic) return false;
      
      let canProgress = true;
      for (let ti = 0; ti < topic.items.length; ti++) {
        let item = topic.items[ti];
        if (item.type == "confirm") {
          if (!item.showContent && !item.accepted) {
            canProgress = false;
          }
        }
        else if (item.type == 'scorm') {
      
          if (item.options && item.options.continueAfterTry) {
            if (item.results) {
              if (!item.results.completed) {
                canProgress = false;
              }
            }
            else{
              canProgress = false;
            }
          } else canProgress = false;
        }
        else if (item.type == 'video' && (item.allow_continue == false) && !item.video_ended) {
          canProgress = false
        }
        
      }
      return canProgress;
    },
    checkForEnd() {
      if (
        (this.currentTopic == this.training.content.length - 1 &&
          !this.training.endingEnabled) ||
        (this.showingEnding && this.training.endingEnabled)
      ) {
        this.showEndButton = true;
        if(this.videoTests){
          axios
            .get(
              `${this.$store.state.api_link}/get-vtest-answers/${this.$store.state.currentTraining}`,
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              this.testsWithAnswers = response.data.tests;
              this.testsWithAnswers.forEach((test)=>{
                test.questions.forEach((question)=>{
                  question.answers = JSON.parse(question.answers);
                })
              })
            })
          .catch((err) => {});
        }
        if (
          this.training.certificate &&
          this.training.certificate.enabled &&
          !this.topicHasTest
        ) {
          if(this.videoTests){
            if(this.testsWithAnswers.every(test=>test.is_test_passed)){
                this.attachCertificate()
              }
          }else{
            this.attachCertificate();
          }
        }
        if (!this.hasEnded && !this.showEndButton) {
          if(this.training_key)
            this.sendPostMessage({ type: "training_end", user_id: this.user_id, training_id: this.$store.state.currentTraining, date: new Date().toISOString(), timestamp: Date.now()})
          if((this.training_key != undefined && this.$route.meta.keyed) || this.training_key == undefined){
            if (this.training.trainingType != 'attest') {
              axios
                .post(
                  `${this.$store.state.api_link}/training/stats/end`,
                  {
                    training: this.$store.state.currentTraining,
                    result: this.testResults,
                    hasTest: this.topicHasTest,
                    is_rented: this.$store.state.currentTrainingRented,
                    key: this.training_key,
                  },
                  this.$store.getters.axiosConfig()
                )
                .then((response) => {
                  if (response.data.status == "success") {
                    this.hasEnded = true;
                  } else {
                    console.log("Error while sending statistics");
                  }
                })
                .catch(() => {
                  this.hasEnded = false;
                  console.log("Error while sending statistics");
                });
            }
          }
        }
      } else {
        this.showEndButton = false;
      }
    },
   convertToHyperlinks(input) {
        // Sanitize the input
        let sanitizedInput = DOMPurify.sanitize(input);

        // Convert URLs to hyperlinks
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        sanitizedInput = sanitizedInput.replace(urlPattern, function(url) {
            return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
        });
        const cleanInput = DOMPurify.sanitize(sanitizedInput, {
            ALLOWED_TAGS: ['a'],
            ALLOWED_ATTR: ['href', 'target', 'rel']
        });

        return cleanInput;
    },
    exitPreviewMode(){
      this.$store.state.previewMode = false;
      this.$router.go(-1)

      // this.$router.push('/edit-training?id='+this.$store.state.currentTraining);
    },
    attachCertificate(){
          axios
            .post(
              `${this.$store.state.api_link}/certificates/attach`,
              {
                cert_id: this.training.certificate.background,
                training_id: this.$store.state.currentTraining,
                training_title: this.training.title,
                expiration_date: this.training.certificate.expiration,
                expires: this.training.certificate.expires,
                user_id: this.training.selectedUser ? this.training.selectedUser : null,
              },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              if (response.data.status == "success") {
                if (response.data.new_cert == 1) {
                  let cert =
                    this.$store.state.certificates.siteCertificates.find(
                      (el) => el.id == this.training.certificate.background
                    );
                  let imgURL = "";
                  if (cert != null) {
                    imgURL = `${this.$store.state.api_assets_link}/${cert.path}`;
                  }
                  this.$store.state.toast.showPopup(
                    {
                      img: imgURL,
                      title: this.toLocal("training.congrats"),
                      message: this.toLocal("training.got_cert"),
                    },
                    3000
                  );
                  this.$store.state.certificates.appendCertificate(
                    response.data.certificate
                  );
                } else {
                  this.$store.state.certificates.updateCertificate(
                    response.data.certificate
                  );
                }
              }
            })
            .catch((err) => {});
    },
    endTrainingTimer() {
      if(this.hasEnded) return
      if(this.training_key)
        this.sendPostMessage({ type: "training_end", user_id: this.user_id, training_id: this.$store.state.currentTraining, date: new Date().toISOString(), timestamp: Date.now()})
      if ((this.training_key != undefined && this.$route.meta.keyed) || this.training_key == undefined) {
        if (this.training.trainingType != 'attest') {
          axios
            .post(
              `${this.$store.state.api_link}/training/stats/end`,
              {
                training: this.$store.state.currentTraining,
                result: this.testResults,
                hasTest: this.topicHasTest,
                is_rented: this.$store.state.currentTrainingRented,
                key: this.training_key,
              },
              this.$store.getters.axiosConfig()
            )
            .then((response) => {
              if (response.data.status == "success") {
                this.hasEnded = true;
              } else {
                console.log("Error while sending statistics");
              }
            })
            .catch(() => {
              this.hasEnded = false;
              console.log("Error while sending statistics");
            });
        }
      }
    },
    endTraining() {
      let comment = this.$refs["commentInput"]
        ? this.$refs["commentInput"].value
        : "";
      if (comment.length) {
        axios
          .post(
            `${this.$store.state.api_link}/training/stats/comment`,
            {
              training: this.$store.state.currentTraining,
              comment: comment,
              is_rented: this.$store.state.currentTrainingRented,
              key: this.training_key,
              user_id: this.training.selectedUser ? this.training.selectedUser.id : null,
              test_id: this.training.trainingType == 'attest' ? this.attestTest ? this.attestTest.version_id : null : null,
            },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if (response.data.status == "success") {
            } else {
              console.log("Error while sending statistics");
            }
          })
          .catch(() => {
            console.log("Error while sending statistics");
          });
      }
      this.finishTraining()
      this.goBack();
      // this.$router.push("/home");
      // this.$store.state.currentTraining = -1;
    },
    saveComment(){
      if(this.isSavingComment || this.savedComment) return;
      this.isSavingComment = true;
       if (this.commentText.length) {
        axios
          .post(
            `${this.$store.state.api_link}/training/stats/comment`,
            { training: this.$store.state.currentTraining, comment: this.commentText, is_rented: this.$store.state.currentTrainingRented, key: this.training_key, },
            this.$store.getters.axiosConfig()
          )
          .then((response) => {
            if (response.data.status == "success") {
              this.savedComment=true;
              setTimeout(()=>{
                this.savedComment=false;
              }, 2000)
            } else {
              console.log("Error while sending statistics");
            }
            this.isSavingComment = false;

          })
          .catch(() => {
            console.log("Error while sending statistics");
            this.isSavingComment = false;
          });
      }
    },
    goBack() {
      if(this.training_prop){
        this.$emit('goBack');
        return;
      }
      this.$store.state.loading.loadingStart();
      if (this.training.trainingType == 'attest' && !this.prevRoute) {
        this.$router.push('/home');
      }
      else {
        this.$router.back();
      }
      this.$store.state.currentTraining = -1;
      setTimeout(() => {
        if (this.prevRoute && this.prevRoute.fullPath != this.$router.currentRoute.fullPath)
          this.$router.go(this.prevRoute.fullPath);
      }, 1000);
    },
    hoverStar(star) {
      this.hoveredStar = star;
    },
    unhoverStar() {
      this.hoveredStar = 0;
    },
    ratingStar(star) {
      this.starRating = star;
      axios
        .post(
          `${this.$store.state.api_link}/training/stats/rate`,
          {
            training: this.$store.state.currentTraining,
            rating: star,
            is_rented: this.$store.state.currentTrainingRented,
            key: this.training_key,
            user_id: this.training.selectedUser ? this.training.selectedUser.id : null,
            test_id: this.training.trainingType == 'attest' ? this.attestTest ? this.attestTest.version_id : null : null,
          },
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          if (response.data.status == "success") {
          } else {
            console.log("Error while sending statistics");
          }
        })
        .catch(() => {
          console.log("Error while sending statistics");
        });
    },
    offset: (el) => {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    },
    handleScroll: function () {
      if (this.training.content == undefined) return;
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      for (let refIndex = 0; refIndex < 100; refIndex++) {
        const currentRef = this.$refs["training-" + refIndex];
        if (currentRef == undefined || currentRef[0] == undefined) break;
        const top = this.offset(currentRef[0]).top;
        let yOffset = -80;
        this.training.content[refIndex].innerScrollTop =
          -(top - scrollY) - yOffset;
      }
    },
    handleNavbar() {
      let topic = -1;
      for (let refIndex = 0; refIndex < 100; refIndex++) {
        const currentRef = this.$refs["training-" + refIndex];
        if (currentRef == undefined || currentRef[0] == undefined) break;
        if (refIndex == 0) topic = -1;
        if (this.training.content[refIndex].innerScrollTop > -200)
          topic = refIndex;
      }
      this.scrolledTopic = topic;
    },
    restartTraining() {
      if(this.videoTests && this.videoTests.length != 0){
        this.videoTests.forEach((test)=>{
          if(!this.videoTestSettings.unlimited_retakes && test.solve_times < this.videoTestSettings.retake_test){
            test.is_triggered = false;
          }
      })
      }
      this.$store.state.loading.loadingStart();
      axios
        .get(
          `${this.$store.state.api_link}/training/info/${this.$store.state.currentTraining}`,
          this.$store.getters.axiosConfig()
        )
        .then((response) => {
          this.trainingInfo = JSON.parse(response.data.data);
          
          this.loadTraining(this.trainingInfo);
        })
        .catch((error) => {
          console.log(error);
        });
      window.scrollTo(0, 0);
      this.showTest = false;
      this.currentTopic = 0;
      this.showingEnding = false;
      this.showEndButton = false;
    },
    showItem(topic, trainingItemIndex) {
      let items = topic.items;
      let canShow = true;
      let previous_items = items.slice(0, trainingItemIndex);
      let unconfirmed_buttons = previous_items.filter(
        (item) => item.type == "confirm" && !item.accepted && !item.showContent
      );

      if(unconfirmed_buttons.length > 0){
        canShow=false;
      }
      let unfinished_scorms = previous_items.filter(
         (item) => item.type == "scorm"
           && (item.options ? item.options.continueAfterTry : true)
           && (!item.results
             || (item.results && !item.results.completed))
      );
      if(unfinished_scorms.length > 0){
        canShow=false;
      }
      let unfinished_videos = previous_items.filter(
        (item) => item.type == "video" && (item.allow_continue == false) && !item.video_ended
      )
      if(unfinished_videos.length > 0){
        canShow=false;
      }
      return canShow;
    },
    setTestTimer(topic, topicIndex) {
      if (
        topic &&
        topic.options &&
        parseInt(topic.options.testLength) &&
        parseInt(topic.options.testLength) != 0
      ) {
        let test_time =
          topic && topic.options
            ? parseInt(topic.options.testLength) *
              (topic.options.testTimeFormat == 1 ? 60 : 1)
            : null;
        if (this.topicTimers[topicIndex] == undefined) {
          this.$set(this.topicTimers, topicIndex, {});
        }
        this.$set(this.topicTimers[topicIndex], "counter", test_time);
        this.$set(this.topicTimers[topicIndex], "initial_time", 1);
        this.setTopicTimer(topicIndex, test_time);
      }
    },
    stopTestTimer(topicIndex) {
      this.$set(this.topicTimers, topicIndex, undefined);
      clearInterval(this.topicTimerObj[topicIndex]);
      this.$set(this.topicTimerObj, topicIndex, undefined);
    },
    setTopicTimer(topicIndex, counter = null) {
      var interval = 1000;
      let time =
        counter == null ? this.topicTimers[topicIndex].counter : counter;
      time *= 60 * 1000;
      let minutes = moment.duration(time).minutes();
      let seconds = moment.duration(time).seconds();
      this.$set(
        this.topicTimers[topicIndex],
        "counter",
        minutes + ":" + seconds
      );
      var timer = setInterval(() => {
        if (time > 0) {
          time -= 1000;
          minutes = moment.duration(time).minutes();
          seconds = moment.duration(time).seconds();
          seconds = seconds < 10 ? "0" + seconds : seconds;
          this.$set(
            this.topicTimers[topicIndex],
            "counter",
            minutes + ":" + seconds
          );

          if (time < 1) {
            this.training.content[topicIndex].lockedForUser = true;
            if (this.training.content[topicIndex].test) {
              this.testTimer(topicIndex, true);
            }
          }
        }
      }, interval);
      this.$set(this.topicTimerObj, topicIndex, timer);
    },
    stopTopicTimer(topicIndex) {
      clearInterval(this.topicTimerObj[topicIndex]);
    },
    testTimer(topicIndex, check) {
      this.stopTopicTimer(topicIndex);
      let test = this.$refs[`testTopic-${topicIndex}`];
      if (!test) return;
      if (Array.isArray(test)) test = test[0];
      this.$set(this.topicTimers[topicIndex], "initial_time", 0);
      if (check) test.checkTest(this.training.content[topicIndex], topicIndex);
    },
    goToTopicFromMenu(item, itemIndex, type = "default") {
      if (!this.training.lockTopics) {
        this.goToTopic(item, itemIndex, type);
      } else if (this.end_stat != null) {
        this.goToTopic(item, itemIndex, type);
      }
    },
    goToTopic(item, itemIndex, type) {
      if (type == "raw") {
        this.gotoRaw(itemIndex);
      } else if (item && item.test) {
        this.gotoTest(itemIndex);
      } else {
        this.goto(itemIndex);
      }
    },
    sendPostMessage(data){
      let data_encoded = JSON.stringify(data);
      window.parent.postMessage(data_encoded, "*");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_training.scss";
h3{
  color: #333333;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  font-family: var(--main-site-font);
}

</style>

<style lang="scss">
@import "@/assets/scss/views/_trainingView.scss";
.task--connects--list{
  position: relative;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.task--connects--part1 {
    .connected{
      right: -42px;
    }
  }
.test-answers-btn{
  position: absolute;
  left: 0;
  top: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  width: 224px;
  padding: 8px 22px;
  border: none;
  font-size: 18px;
  text-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: 0.25s;
  &:hover{
    background-color: #dfdfdf;
  }

}
.vtest-results-container{
  position: relative;
  width: 720px;
  background-color: white;
  background: #FFFFFF;
  border-radius: 21px;  
  max-width: 90vw;
  @media(max-width: 1000px){
    height: fit-content;
  }
  .test-answers-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    left: -145px;
    width: 105px;
    border-radius: 30px;
    text-align: center;
    @media(max-width: 1000px){
      left: 1vw;
      top: 1vw;
    }
  }
}
.answers-container{
  display: flex;
  flex-direction: column;
  .test-title{
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 40px;
    font-size: 36px;
    font-weight: 600;
    background-color: #E2E2E2;
    border-radius: 21px 21px 0 0;
  }
}
.single-answer-box{
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 40px;
  max-height: 800px;
  overflow-y: auto;
  margin-bottom: 0;
  @media(max-width: 1000px){
    margin: 20px;
    margin-top: 30px;
    padding: 20px;
  }
  &:nth-child(2){
    margin-top: 18px;
  }
  &:not(:last-child){
    border-bottom: 2px solid #E2E2E2;
  }
  .question-title{
    width: 100%;
    text-align: start;
  }
  .answers-inner{
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    gap: 12px;
    .single-answer{
      text-align: start;
      background: #FFFFFF;
      border: 1px solid #7D7D7D;
      border-radius: 20px;
      padding: 4px 4px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      &.correct{
        background: #85E091;
        border: none;
      }
      .letter{
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #EFEFEF;
        margin-right: 12px;
      }
    }
  }
}
.exit--preview{
  svg{
    path{
      fill: var(--primary-color)
    }
  }
}
.task--answer--text{
  a{
    color: #4c4cd0;
    text-decoration: underline;
  }
}
</style>
