<template>
    <div class="phone-login">
        <span class="info" v-if="!codeSent">
            {{toLocal('login.phone_login_info_label')}}
        </span>
        <div class="info" v-else>
            {{toLocal('login.phone_password_sent')}} <span class="phone">{{ mobile_prefix }}{{ phone_number }}</span>.
            <br/>
            <br/>
            {{toLocal('login.enter_password_to_login')}} <span class="try-again" @click="codeSent=false">{{toLocal('generic.start_again')}}</span>.
        </div>
        <div class="form" v-if="!codeSent">
            <label for="phone">{{ toLocal('generic.phone_number') }}:</label>
            <div class="inputs-container">
                 <vue-tel-input 
                    v-model="phone_number" 
                    mode="international" 
                    :autoFormat="false" 
                    :dropdownOptions="{showFlags: false, showDialCodeInSelection:true, showDialCodeInList: true, showSearchBox: true}" 
                    defaultCountry="lt"
                    :preferredCountries="['lt', 'lv', 'ee']"
                    :inputOptions="{showDialCode: false, placeholder: '00000000'}"
                    :validCharactersOnly="true"
                    @country-changed="countryChange">
                    <template v-slot:arrow-icon>
                        <svg class="arrow-icon" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.933058 1.05806C1.17714 0.813981 1.57286 0.813981 1.81694 1.05806L6.375 5.61612L10.9331 1.05806C11.1771 0.813981 11.5729 0.813981 11.8169 1.05806C12.061 1.30214 12.061 1.69786 11.8169 1.94194L6.81694 6.94194C6.57286 7.18602 6.17714 7.18602 5.93306 6.94194L0.933058 1.94194C0.688981 1.69786 0.688981 1.30214 0.933058 1.05806Z" fill="#333333"/>
                        </svg>
                    </template>
                </vue-tel-input>
            </div>
        </div>
        <div class="form" v-else>
            <label for="phone">{{toLocal('login.password')}}</label>
            <input type="text" :placeholder="toLocal('generic.enter_text')" v-model="password">
        </div>
        <div class="error-message" v-if="errorMessage && errorMessage.length > 0">
            {{ errorMessage }}
        </div>
        <div class="button-container" >
            <div class="remember-me">
                <div class="form-check remember--wrapper">
                <input
                    id="remember"
                    v-model="remember"
                    class="form-check-input form-checkbox"
                    type="checkbox"
                    name="remember"
                    value="true"
                >

                <label
                    class="form-check-label remember--text"
                    for="remember"
                >
                    {{ toLocal("login.remember_me") }}
                </label>
                </div>
            </div>
            <button class="send" @click="sendCode">
                {{codeSent ? toLocal('login.login') : toLocal('generic.send_label')}}
            </button>
        </div>
    </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import axios from 'axios';
export default {
    components: {
        VueTelInput 
    },
    data() {
        return {
            mobile_prefix: '+370',
            phone_number: '',
            remember: false,
            codeSent: false,
            password: '',
            errorMessage: ''
        }
    },

    methods: {
        countryChange(countryObject) {
            let dialCode = countryObject.dialCode;
            this.mobile_prefix = dialCode;
        },
        sendCode() {
            this.errorMessage = '';
            if (!this.codeSent) {
                if((this.mobile_prefix + this.phone_number).length < 7) {
                    this.errorMessage = this.toLocal('errors.phone_format_not_valid');
                    return
                }
                else if((this.mobile_prefix + this.phone_number).length > 15) {
                    this.errorMessage = this.toLocal('errors.phone_format_not_valid');
                    return
                }
                if(!this.mobile_prefix.startsWith('+')) {
                    this.mobile_prefix = '+' + this.mobile_prefix
                }
                axios.post(`${this.$store.state.api_link}/login/phone`, {
                    number: this.mobile_prefix + this.phone_number
                }, this.$store.getters.axiosConfig())
                .then((res) => {
                    this.codeSent = true;
                })
                .catch(error => {
                    this.errorMessage = this.toLocal(error.response.data.message);
                    console.log(error);
                });
            }
            else {
                axios.post(`${this.$store.state.api_link}/login/code`, {
                    code: this.password,
                    remember: this.remember
                }, this.$store.getters.axiosConfig())
                    .then((res) => {
                    this.$store.state.loading.loadingStart();
                    this.$router.push("/");
                    this.$store.state.pageRerenderKey++;
                    this.$store.state.loading.loadingDone();    
                    
                })
                .catch(error => {
                    console.log(error);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.phone-login{
    display: flex;
    flex-direction: column;
    color: #333333;
}
.info{
    margin-bottom: 20px;
    padding: 0 8px;
    font-size: 16px;
}
.form{
    display: flex;
    flex-direction: column;
}
.button-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
}
label{
    padding: 0 8px 8px;
    font-weight: 600;
}
button{
    background-color: #333333;
    border: none;
    border-radius: 20px;
    color: white;
    padding: 6px 40px;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    box-shadow:  0 2px 3px 0 rgba(0, 0, 0, 0.16);
    transition: background-color 0.25s;
    &:hover{
        background-color: #7D7D7D;
    }
}
.remember--wrapper {
    position: relative;
        border-radius: 7px;
        display: flex;
        align-items: center;
        height: 34px;
        padding: 4px 12px;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);

        background: #E2E2E2;

        .remember--text {
            font-size: 13px;        
            padding: 0;
            vertical-align: top;
            display: flex;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;

            color: #333333;
            align-items: center;

        }    

        @media (max-width: 768px) {
          display: none;
        }
    }
input{
    box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    border:1px solid #F4F4F4;
    padding: 8px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    width: 100%;
}
.phone{
    font-weight: 600;
}
.try-again{
    cursor: pointer;
    text-decoration: underline;
}
.error-message{
    color: rgba(255, 94, 94, 0.692);
    font-size: 14px;
    margin-top: 5px;
    padding: 0 8px;
}
</style>
<style lang="scss">
    .vue-tel-input{
        display: flex;
        align-items: center;
        gap: 8px;
        border:none
    }
    .vti__dropdown, .vti__input{
        box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        border:1px solid #F4F4F4;
        border-radius: 6px;
        padding: 4px 12px;
        font-size: clamp(16px, 1.2vw, 18px);
        height: 40px;
        background-color: white;
        color: #333333;
        .vti__country-code{
            color: #333333;
        }
        .vti__selection{
            display: flex;
            gap: 4px;
        }
        &:hover{
            background-color: white;
        }
        .vti__dropdown-item {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            justify-content: flex-end;
            span{
                width: 40px;
                min-width: 40px;
                max-width: 40px;
            }
        }
        .vti__dropdown-list{
            box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.16);
            border:1px solid #F4F4F4;
            border-radius: 0 6px 6px 6px;

        }
        .arrow-icon{
            transition: transform 0.3s ease;
        }
        &.open{
            .arrow-icon{
                transform: rotate(180deg);
            }
        }
    }
</style>